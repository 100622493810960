(function () {
    'use strict';

    angular
        .module('josdaviApp')
        .controller('MessageController', MessageController);

    MessageController.$inject = ['GLOBAL_CONSTANTS', 'Restangular', 'Principal', '$scope', '$state', 'Message', 'TableService'];

    function MessageController(GLOBAL_CONSTANTS, Restangular, Principal, $scope, $state, Message, TableService) {

        var vm = this;
        vm.datePickerOpenStatus = {};
        vm.datePickerOpenStatus.date = false;
        vm.openCalendar = openCalendar;

        function openCalendar(date) {
            vm.datePickerOpenStatus[date] = true;
        }

        vm.predicate = 'id';
        vm.date = null;
        vm.contenu = null;
        vm.telephone = null;


        $scope.$watch("vm.contenu", function (d) {
            vm.loadAll();
        });

        $scope.$watch("vm.telephone", function (d) {
            vm.loadAll();
        });

        $scope.$watch("vm.date", function (d) {
            vm.loadAll();
        });


        var dialogData = {
            addData: {},
            editData: {},
            deleteData: {}
        };


        var entitySearchParams = function () {
            return {};
        };
        var entityParams = function () {
            return {
                date: vm.date,
                telephone: vm.telephone,
                contenu: vm.contenu
            };
        };

        var datas = {
            scope: $scope,
            vm: vm,
            entity: Message,
            entitySearch: Message,
            dialogData: dialogData,
            entitySearchParams: entitySearchParams,
            entityParams: entityParams
        };
        TableService.init(datas);

    }
})();
