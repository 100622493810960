(function () {
    'use strict';

    angular
        .module('josdaviApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('parameters.etablissement', {
                parent: 'parameters',
                url: '/etablissements',
                title: 'Etablissements',
                sidebarMeta: {
                    icon: 'fa fa-graduation-cap',
                    order: 6
                },
                data: {
                    authorities: ['ROLE_RESPONSABLE_INSCRIPTION','ROLE_RESPONSABLE','ROLE_ADMIN'],
                    pageTitle: 'josdaviApp.etablissement.home.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/etablissement/etablissements.html',
                        controller: 'EtablissementController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('etablissement');
                        $translatePartialLoader.addPart('typeEtablissement');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }]
                }
            }).state('etablissement-classes', {
            parent: 'parameters.etablissement',
            url: '/classes-etablissement',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'josdaviApp.classe.home.title'
            },
            views: {
                'etablissement-classes': {
                    templateUrl: 'app/entities/classe/classes.html',
                    controller: 'ClasseController',
                    controllerAs: 'vm'
                }
            },
            params: {
                etablissement: null
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('classe');
                    $translatePartialLoader.addPart('niveau');
                    $translatePartialLoader.addPart('cycle');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        });    }

})();
