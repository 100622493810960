(function () {
    'use strict';

    angular
        .module('josdaviApp')
        .controller('HomeController', HomeController);

    HomeController.$inject = ['$http', 'baConfig', '$scope', 'Principal', 'LoginService', '$state'];

    function HomeController($http, baConfig, $scope, Principal, LoginService, $state) {
        var vm = this;
        var layoutColors = baConfig.colors;
        vm.account = null;
        vm.isAuthenticated = null;
        vm.login = LoginService.open;
        vm.showLoginDialog = showLoginDialog;
        vm.loadDatas = loadDatas;
        /**
         * Contient la liste des établissements et leurs effectif associé
         * @type {Array}
         */
        vm.effectifs = [];
        vm.options = {
            responsive: true,
            maintainAspectRatio: false,
            scales: {
                yAxes: [{
                    ticks: {
                        beginAtZero: true
                    }
                }]
            }
        };

        vm.datasEPE = [];
        vm.datasCPE = [];
        vm.datasIPT = [];
        vm.datasIPA = [];
        vm.datasIPE = [];

        vm.labelsEPE = [];
        vm.labelsCPE = [];
        vm.labelsIPT = [];
        vm.labelsIPA = [];
        vm.labelsIPE = [];

        vm.seriesIPA = [];
        vm.seriesIPE = [];
        vm.estUtilisateurSimple = true;

        $scope.$on('authenticationSuccess', function () {
            getAccount();
        });
        getAccount();

        function getAccount() {
            Principal.identity().then(function (account) {
                if (account !== null && account !== undefined) {
                    vm.estUtilisateurSimple = account.authorities.indexOf('ROLE_ENVOIE_GROUPE') ||
                        account.authorities.indexOf('ROLE_ASSISTANT') !== -1 ||
                        account.authorities.indexOf('ROLE_RESPONSABLE_ETABLISSEMENT') !== -1;
                    vm.isAuthenticated = Principal.isAuthenticated;
                    vm.loadDatas();
                }
                vm.showLoginDialog();
            });
        }


        function showLoginDialog() {
            if (!Principal.isAuthenticated()) {
                vm.login();
            }
        }

        function loadDatas() {
            $http.get("api/reporting/stats?type=cpe").then(
                function (res) {
                    vm.datasCPE = res.data.data;
                    vm.labelsCPE = res.data.names;
                });
            $http.get("api/reporting/stats?type=ipt").then(
                function (res) {
                    vm.datasIPT = res.data.data;
                    vm.labelsIPT = res.data.names;
                });
            $http.get("api/reporting/stats?type=ipa").then(
                function (res) {
                    vm.datasIPA = res.data.datas;
                    vm.labelsIPA = res.data.names;
                    vm.seriesIPA = res.data.series;
                });
            $http.get("api/reporting/stats?type=ipe").then(
                function (res) {
                    vm.datasIPE = res.data.datas;
                    vm.labelsIPE = res.data.names;
                    vm.seriesIPE = res.data.series;
                });
            $http.get("api/reporting/effectifs?page=0&size=200&sort=nom,asc").then(
                function (res) {
                    vm.effectifs = res.data;
                });
        }
    }
})();
