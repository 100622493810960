(function () {
    'use strict';

    angular
        .module('josdaviApp')
        .controller('ParentController', ParentController);

    ParentController.$inject = ['EtablissementClasses', 'GLOBAL_CONSTANTS', 'Etablissement', '$scope', '$state', 'Parent', 'TableService'];

    function ParentController(EtablissementClasses, GLOBAL_CONSTANTS, Etablissement, $scope, $state, Parent, TableService) {

        var vm = this;

        vm.loadClasses = loadClasses;
        vm.loadEtablissements = loadEtablissements;
        vm.loadEtablissements();


        $scope.$watch("vm.etablissementId", function (id) {
            if (id !== undefined && id !== null) {
                vm.loadClasses(id);
            }
        });

        function loadEtablissements() {
            vm.etablissements = Etablissement.query({
                size: GLOBAL_CONSTANTS.MAX_ITEMS_PER_PAGE,
                sort: 'nom,asc'
            });
        }

        function loadClasses(etablissementId) {
            EtablissementClasses.query({
                id: etablissementId,
                size: GLOBAL_CONSTANTS.MAX_ITEMS_PER_PAGE,
                sort: 'nom,asc'
            }, function (res) {
                vm.classes = res;
            });
        }

        var addItemData = {
            templateUrl: 'app/entities/parent/parent-dialog.html',
            controller: 'ParentDialogController',
            controllerAs: 'vm',
            backdrop: 'static',
            size: 'md',
            resolve: {
                entity: function () {
                    return {
                        nom: null,
                        prenom: null,
                        telephone: null,
                        email: null,
                        profession: null,
                        statut: null,
                        langueId: 0,
                        moyenCommunication: 'APPLICATION',
                        id: null
                    };
                }
            }
        };


        /**
         *Modification d'un élément
         */
        var editItemData = {
            templateUrl: 'app/entities/parent/parent-dialog.html',
            controller: 'ParentDialogController',
            controllerAs: 'vm',
            backdrop: 'static',
            size: 'md',
            resolve: {
                entity: null
            }
        };

        /**
         * Suppresion d'un élément
         */
        var deleteItemData = {
            templateUrl: 'app/entities/parent/parent-delete-dialog.html',
            controller: 'ParentDeleteController',
            controllerAs: 'vm',
            size: 'sm',
            resolve: {
                entity: null
            }
        };

        var dialogData = {
            addData: addItemData,
            editData: editItemData,
            deleteData: deleteItemData
        };
        var entitySearchParams = function () {
            return {};
        };


        var entityParams = function () {
            return {};
        };

        var datas = {
            scope: $scope,
            vm: vm,
            entity: Parent,
            entitySearch: Parent,
            dialogData: dialogData,
            entitySearchParams: entitySearchParams,
            entityParams: entityParams
        };

        TableService.init(datas);

        $scope.$watch("vm.classeId", function (id) {
            var entitySearchParams = function () {
                return {};
            };

            var entityParams = function () {
                return {
                    classeId: id
                };
            };

            var datas = {
                scope: $scope,
                vm: vm,
                entity: Parent,
                entitySearch: Parent,
                dialogData: dialogData,
                entitySearchParams: entitySearchParams,
                entityParams: entityParams
            };

            TableService.init(datas);

        });

    }
})();
