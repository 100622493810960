(function() {
    'use strict';

    angular
        .module('josdaviApp')
        .controller('UserExtraDialogController', UserExtraDialogController);

    UserExtraDialogController.$inject = ['Notification','$timeout', '$scope', '$uibModalInstance', '$q', 'DataUtils', 'entity', 'UserExtra'];

    function UserExtraDialogController (Notification,$timeout, $scope, $uibModalInstance, $q, DataUtils, entity, UserExtra) {
        var vm = this;

        vm.user = entity;
      

        vm.clear = clear;
        vm.byteSize = DataUtils.byteSize;
        vm.openFile = DataUtils.openFile;
        vm.save = save;
        $timeout(function (){
            angular.element("input[type='text']:eq(0)").focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {

            vm.isSaving = true;
            if (vm.user.id !== null) {
                UserExtra.update(vm.user, onSaveSuccess, onSaveError);
            } else {
                if(vm.confirmPassword==vm.user.password){
                    UserExtra.save(vm.user, onSaveSuccess, onSaveError);
                }else{
                    Notification.error("Le mot de passe et sa confirmation ne sont pas égaux !");
                    onSaveError();
                }
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('josdaviApp:userExtraUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }
        
        vm.setPhoto = function ($file, userExtra) {
            if ($file && $file.$error === 'pattern') {
                return;
            }
            if ($file){
                DataUtils.toBase64($file, function(base64Data) {
                    $scope.$apply(function() {
                        userExtra.photo = base64Data;
                        userExtra.photoContentType = $file.type;
                    });
                });
            }
        };
    }
})();
