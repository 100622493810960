(function () {
    'use strict';

    angular
        .module('josdaviApp')
        .controller('InscriptionController', InscriptionController);

    InscriptionController.$inject = ['_', 'Niveau', 'GLOBAL_CONSTANTS', 'Etablissement', 'EtablissementClasses', 'Notification', 'Restangular', '$timeout', '$scope', '$state', 'Inscriptions', 'DataUtils', 'DateUtils'];

    function InscriptionController(_, Niveau, GLOBAL_CONSTANTS, Etablissement, EtablissementClasses, Notification, Restangular, $timeout, $scope, $state, Inscriptions, DataUtils, DateUtils) {
        var vm = this;

        $timeout(function () {
            angular.element("input[type='text']:eq(0)").focus();
        });
        vm.noPicture = false;
        vm.dateFormat = GLOBAL_CONSTANTS.DATE_FORMAT;
        vm.dateFormatFR = GLOBAL_CONSTANTS.DATE_FORMAT_FR;

        vm.niveaux = [];
        vm.byteSize = DataUtils.byteSize;
        vm.openFile = DataUtils.openFile;
        vm.removePicture = removePicture;
        vm.openCalendar = openCalendar;
        vm.setPhoto = setPhoto;
        vm.save = save;
        vm.clear = clear;
        vm.initForm = initForm;
        vm.loadEleves = loadEleves;
        vm.loadParents = loadParents;
        vm.filtrerClasse = filtrerClasse;
        vm.initForm();

        /*Charger la liste des niveaux de classes*/
        Niveau.query({
            size: GLOBAL_CONSTANTS.MAX_ITEMS_PER_PAGE,
            sort: 'nom,asc'
        }, function (res) {
            vm.niveaux = res;
        });
        /**
         *Liste des colonnes à afficher lors de selection d'un élève dans la boite de dialogue
         */
        vm.eleveColumns = [{
            name: 'numero',
            title: 'Numéro'
        }, {
            name: 'nom',
            title: 'Nom'
        }, {
            name: 'prenom',
            title: 'Prénom'
        }, {
            name: 'telephone',
            title: 'Téléphone'
        }];

        /**
         *Liste des colonnes à afficher lors de selection d'un parent dans la boite de dialogue
         */
        vm.parentColumns = [{
            name: 'nom',
            title: 'Nom'
        }, {
            name: 'prenom',
            title: 'Prénom'
        }, {
            name: 'email',
            title: 'E-mail'
        }, {
            name: 'telephone',
            title: 'Téléphone'
        }];

        /*Observations des changements sur certains champs*/


        /*Pendant qu'on enregistre le nom de l'eleve, en général, son responsable a le 
         *même nom, donc on donne le même nom au responsable par défaut pour gagner en temps.
         */
        $scope.$watch("vm.inscription.eleve.nom", function (nom) {
            if (angular.isDefined(nom)) {
                vm.inscription.parent.nom = nom;
            }
        });

        /**
         *A la selection de l'établissement, on charge par défaut la liste
         *des classes qui y existent.
         */
        $scope.$watch("vm.inscription.etablissementId", function (etablissementId) {
            if (angular.isDefined(etablissementId) && etablissementId !== null) {
                EtablissementClasses.query({
                    id: etablissementId,
                    size: GLOBAL_CONSTANTS.MAX_ITEMS_PER_PAGE,
                    sort: 'nom,asc'
                }, function (res) {
                    vm.classes = res;
                });
            }
        });
        /**
         * Si le type d'inscription change, on réinitialise le formulaire de préférence
         */
        $scope.$watch("vm.inscription.isInscription", function (isInscription) {
            if (angular.isDefined(isInscription) && isInscription !== null) {
                vm.initForm();
            }
        });

        $scope.$on("onLoadEleves", function () {
            vm.loadEleves();
        });

        $scope.$on("onLoadParents", function () {
            vm.loadParents();
        });

        /*
                $scope.$watch("vm.inscription.classe.cycle", function (cycle) {
                    if (angular.isDefined(cycle)) {
                    }
                });
        */

        $scope.$watch("vm.inscription.eleveId", function (id) {
            if (id !== undefined && id !== null) {
                if (vm.inscription.isInscription) {
                    vm.inscription.eleve = _.find(vm.eleves, {
                        'id': id
                    });
                    vm.inscription.eleve.dateNaissance = DateUtils.convertLocalDateFromServer(vm.inscription.eleve.dateNaissance);
                } else { //C'est une réinscription, l'élève existe déjà avec un responsable un établissement et une classe
                    Restangular.one('eleves', id).customGET('infos-inscription').then(function (insc) {
                        if (vm.inscription === undefined) {
                            vm.inscription = {};
                        }
                        vm.inscription.eleve = insc.eleve;
                        vm.inscription.parent = insc.parent;
                        vm.inscription.parentId = insc.parent.id;
                        vm.inscription.etablissement = insc.etablissement;
                        vm.inscription.etablissementId = insc.etablissementId;
                        vm.inscription.classe = insc.classe;
                        vm.inscription.eleve.dateNaissance = DateUtils.convertLocalDateFromServer(vm.inscription.eleve.dateNaissance);
                    });
                }
            }
        });

        $scope.$watch("vm.inscription.parentId", function (id) {
            if (id !== undefined && id !== null) {
                var p = _.find(vm.parents, {
                    'id': id
                });
                angular.forEach(p, function (v, k) {
                    vm.inscription.parent[k] = v;
                });
            }
        });
        $scope.$watch("vm.inscription.nouvelleClasse", function (b) {
            if (vm.inscription.classe !== undefined && vm.inscription.classe !== null) {
                vm.inscription.classe.id = null;
            }
        });

        $scope.$watch("vm.inscription.classe.id", function (b) {
            if (b !== undefined && b !== null) {
                vm.inscription.classe = _.find(vm.classes, {
                    'id': b
                });
            }
        });
        vm.etablissements = Etablissement.query({
            size: GLOBAL_CONSTANTS.MAX_ITEMS_PER_PAGE,
            sort: 'nom,asc'
        });

        function loadEleves() {
            Restangular.all('eleves').getList().then(function (eleves) {
                vm.eleves = eleves;
            });
        }

        function loadParents() {
            Restangular.all('parents').getList().then(function (parents) {
                vm.parents = parents;
            });
        }

        function setPhoto($file, eleve) {
            if ($file && $file.$error === 'pattern') {
                return;
            }
            if ($file) {    
                DataUtils.toBase64($file, function (base64Data) {
                    $scope.$apply(function () {
                        eleve.photo = base64Data;
                        eleve.photoContentType = $file.type;
                    });
                });
            }
        }

        function filtrerClasse(item) {
            if (vm.inscription.classe !== undefined) {
                return item.cycle === vm.inscription.classe.cycle && item.niveau === vm.inscription.classe.niveau;
            }
            return false;
        }

        function openCalendar(date) {
            vm.datePickerOpenStatus[date] = true;
        }

        function removePicture() {
            vm.inscription.eleve.photo = null;
            vm.inscription.eleve.photoContentType = null;
            vm.noPicture = false;
        }

        function save() {
            Inscriptions.post(vm.inscription).then(function () {
                Notification.success({
                    title: 'Succès',
                    message: "L'élève est inscrit avec succès"
                });

                vm.initForm();
            }, function (msg) {
            });
        }


        function clear() {
            vm.initForm();
        }

        /*Initialisations*/
        function initForm() {
            vm.datePickerOpenStatus = {};
            vm.inscription = {
                'isInscription':
                    (vm.inscription !== undefined &&
                        vm.inscription.isInscription !== undefined) ? vm.inscription.isInscription : true
            };
            vm.datePickerOpenStatus.dateNaissance = false;
            vm.inscription.eleve = {};
            vm.inscription.classe = {
                'cycle': 'PREMIER'
            };
            vm.inscription.parent = {
                nom: '-',
                prenom: '-',
                numeroCarte: '-',
                lien: 'INCONNU',
                typeCarte: 'INCONNU',
                moyenCommunication: 'APPLICATION'
            };
            vm.inscription.etablissementId = null;
            vm.inscription.eleveId = null;
            vm.inscription.parentId = null;
            vm.inscription.nouvelleClasse = false;
        }
    }
})();
