(function () {
    'use strict';

    angular
        .module('josdaviApp')
        .controller('SeanceCoursController', SeanceCoursController);

    SeanceCoursController.$inject = ['SeanceCoursByClasse', 'EtablissementClasses', 'Etablissement', 'Classe', '$scope', 'TableService', '$state', 'SeanceCours', 'GLOBAL_CONSTANTS'];

    function SeanceCoursController(SeanceCoursByClasse, EtablissementClasses, Etablissement, Classe, $scope, TableService, $state, SeanceCours, GLOBAL_CONSTANTS) {

        var vm = this;
        vm.loadClasses = loadClasses;
        vm.loadEtablissements = loadEtablissements;
        vm.loadEtablissements();

        $scope.$watch("vm.etablissementId", function (id) {
            if (id !== undefined && id !== null) {
                vm.loadClasses(id);
            }
        });

        function loadEtablissements() {
            vm.etablissements = Etablissement.query({
                size: GLOBAL_CONSTANTS.MAX_ITEMS_PER_PAGE,
                sort: 'nom,asc'
            });
        }

        function loadClasses(etablissementId) {
                EtablissementClasses.query({
                    id: etablissementId,
                    size: GLOBAL_CONSTANTS.MAX_ITEMS_PER_PAGE,
                    sort: 'nom,asc'
                }, function (res) {
                    vm.classes = res;
                });
        }

        $scope.$watch("vm.classeId", function (id) {
            if (id !== undefined && id !== null) {

                var addItemData = {
                    templateUrl: 'app/entities/seance-cours/seance-cours-dialog.html',
                    controller: 'SeanceCoursDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'md',
                    resolve: {
                        entity: function () {
                            return {
                                heureDebut: null,
                                duree: null,
                                type: null,
                                jour: null,
                                id: null
                            };
                        }
                    }
                };


                /**
                 *Modification d'un élément
                 */
                var editItemData = {
                    templateUrl: 'app/entities/seance-cours/seance-cours-dialog.html',
                    controller: 'SeanceCoursDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'md',
                    resolve: {
                        entity: null
                    }
                };

                /**
                 * Suppresion d'un élément
                 */
                var deleteItemData = {
                    templateUrl: 'app/entities/seance-cours/seance-cours-delete-dialog.html',
                    controller: 'SeanceCoursDeleteController',
                    controllerAs: 'vm',
                    size: 'sm',
                    resolve: {
                        entity: null
                    }
                };

                var dialogData = {
                    addData: addItemData,
                    editData: editItemData,
                    deleteData: deleteItemData
                };
                var entitySearchParams = function () {
                    return {};
                };

                var entityParams = function () {
                    return {
                        id: id
                    };
                };

                var datas = {
                    scope: $scope,
                    vm: vm,
                    entity: SeanceCoursByClasse,
                    entitySearch: SeanceCoursByClasse,
                    dialogData: dialogData,
                    entitySearchParams: entitySearchParams,
                    entityParams: entityParams
                };

                TableService.init(datas);
            }
        });

        var addItemData = {
            templateUrl: 'app/entities/seance-cours/seance-cours-dialog.html',
            controller: 'SeanceCoursDialogController',
            controllerAs: 'vm',
            backdrop: 'static',
            size: 'md',
            resolve: {
                entity: function () {
                    return {
                        heureDebut: null,
                        duree: null,
                        type: null,
                        jour: null,
                        id: null
                    };
                }
            }
        };


        /**
         *Modification d'un élément
         */
        var editItemData = {
            templateUrl: 'app/entities/seance-cours/seance-cours-dialog.html',
            controller: 'SeanceCoursDialogController',
            controllerAs: 'vm',
            backdrop: 'static',
            size: 'md',
            resolve: {
                entity: null
            }
        };

        /**
         * Suppresion d'un élément
         */
        var deleteItemData = {
            templateUrl: 'app/entities/seance-cours/seance-cours-delete-dialog.html',
            controller: 'SeanceCoursDeleteController',
            controllerAs: 'vm',
            size: 'sm',
            resolve: {
                entity: null
            }
        };

        var dialogData = {
            addData: addItemData,
            editData: editItemData,
            deleteData: deleteItemData
        };
        var entitySearchParams = function () {
            return {};
        };

        var entityParams = function () {
            return {};
        };

        var datas = {
            scope: $scope,
            vm: vm,
            entity: SeanceCours,
            entitySearch: SeanceCours,
            dialogData: dialogData,
            entitySearchParams: entitySearchParams,
            entityParams: entityParams
        };

        TableService.init(datas);
    }
})();
