(function () {
    'use strict';

    angular
        .module('josdaviApp')
        .controller('EtablissementController', EtablissementController);

    EtablissementController.$inject = ['$scope', '$state', 'Etablissement', 'EtablissementSearch', 'TableService'];

    function EtablissementController($scope, $state, Etablissement, EtablissementSearch, TableService) {

        var vm = this;
        var addItemData = {
            templateUrl: 'app/entities/etablissement/etablissement-dialog.html',
            controller: 'EtablissementDialogController',
            controllerAs: 'vm',
            backdrop: 'static',
            size: 'md',
            resolve: {
                entity: function () {
                    return {
                        numero: null,
                        nom: null,
                        type: null,
                        adresse: null,
                        telephone: null,
                        email: null,
                        id: null
                    };
                }
            }
        };


        /**
         *Modification d'un élément
         */
        var editItemData = {
            templateUrl: 'app/entities/etablissement/etablissement-dialog.html',
            controller: 'EtablissementDialogController',
            controllerAs: 'vm',
            backdrop: 'static',
            size: 'md',
            resolve: {
                entity: null
            }
        };

        /**
         * Suppresion d'un élément
         */
        var deleteItemData = {
            templateUrl: 'app/entities/etablissement/etablissement-delete-dialog.html',
            controller: 'EtablissementDeleteController',
            controllerAs: 'vm',
            size: 'sm',
            resolve: {
                entity: null
            }
        };

        var dialogData = {
            addData: addItemData,
            editData: editItemData,
            deleteData: deleteItemData
        };
        var entitySearchParams = function () {
            return {};
        };

        var entityParams = function () {
            return {};
        };

        var datas = {
            scope: $scope,
            vm: vm,
            entity: Etablissement,
            entitySearch: Etablissement,
            dialogData: dialogData,
            entitySearchParams: entitySearchParams,
            entityParams: entityParams
        };
        TableService.init(datas);
    }
})();