/**
 * Created by k.danovsky on 13.05.2016.
 */

(function() {
    'use strict';

    config.$inject = ["baConfigProvider", "colorHelper", "$provide"];
    chartJsConfig.$inject = ["ChartJsProvider", "baConfigProvider"];
    uiViewScrollDecorator.$inject = ["$delegate", "$anchorScroll", "baUtil"];
    angular.module('BlurAdmin.theme')
        .config(config)
        .config(chartJsConfig);

    /** @ngInject */
    function chartJsConfig(ChartJsProvider, baConfigProvider) {
        var layoutColors = baConfigProvider.colors;
        // Configure all charts
        ChartJsProvider.setOptions({
            chartColors: [layoutColors.primary, layoutColors.danger, layoutColors.warning, layoutColors.success, layoutColors.info, layoutColors.default, layoutColors.primaryDark, layoutColors.successDark, layoutColors.warningLight, layoutColors.successLight, layoutColors.primaryLight],
            responsive: true,
            maintainAspectRatio: false,
            animation: {
                duration: 2500
            },
            legend: {
                display: false,
                position: 'left',
                labels: {
                    fontColor: layoutColors.defaultText
                }
            }
        });
        /*nfigure all line charts
        ChartJsProvider.setOptions('Line', {
            datasetFill: false
        });
        // Configure all radar charts
        ChartJsProvider.setOptions('radar', {
            scale: {
                pointLabels: {
                    fontColor: layoutColors.defaultText
                },
                ticks: {
                    maxTicksLimit: 5,
                    display: false
                }
            }
        });
        // Configure all bar charts
        ChartJsProvider.setOptions('bar', {
            tooltips: {
                enabled: false
            }
        });*/
    }


    /** @ngInject */
    function config(baConfigProvider, colorHelper, $provide) {
        $provide.decorator('$uiViewScroll', uiViewScrollDecorator);
        baConfigProvider.changeTheme({
            blur: false
        });
        //
        baConfigProvider.changeColors({
            //default: '#4e4e55',
            default: '#e65100',
            defaultText: '#e2e2e2',
            dashboard: {
                white: '#ffffff',
            },
        });
    }

    /** @ngInject */
    function uiViewScrollDecorator($delegate, $anchorScroll, baUtil) {
        return function(uiViewElement) {
            if (baUtil.hasAttr(uiViewElement, "autoscroll-body-top")) {
                $anchorScroll();
            } else {
                $delegate(uiViewElement);
            }
        };
    }
})();
