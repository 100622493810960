(function() {
    'use strict';

    angular
        .module('josdaviApp')
        .controller('PeriodeDialogController', PeriodeDialogController);

    PeriodeDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'Periode', 'Bulletin', 'Note', 'AnneeScolaire'];

    function PeriodeDialogController($timeout, $scope, $stateParams, $uibModalInstance, entity, Periode, Bulletin, Note, AnneeScolaire) {
        var vm = this;

        vm.periode = entity;
        vm.clear = clear;
        vm.datePickerOpenStatus = {};
        vm.openCalendar = openCalendar;
        vm.save = save;
        vm.bulletins = Bulletin.query();
        vm.notes = Note.query();
        vm.anneescolaires = AnneeScolaire.query();
        if (vm.periode.dateDebut != null) {
            vm.periode.dateDebut = new Date(vm.periode.dateDebut);
        }

        if (vm.periode.dateFin != null) {
            vm.periode.dateFin = new Date(vm.periode.dateFin);
        }

        $timeout(function() {
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        function save() {
            vm.isSaving = true;
            if (vm.periode.id !== null) {
                Periode.update(vm.periode, onSaveSuccess, onSaveError);
            } else {
                Periode.save(vm.periode, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess(result) {
            $scope.$emit('josdaviApp:periodeUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError() {
            vm.isSaving = false;
        }

        vm.datePickerOpenStatus.dateDebut = false;
        vm.datePickerOpenStatus.dateFin = false;

        function openCalendar(date) {
            vm.datePickerOpenStatus[date] = true;
        }
    }
})();