(function () {
    'use strict';

    angular
        .module('josdaviApp')
        .controller('AnneeScolaireDialogController', AnneeScolaireDialogController);

    AnneeScolaireDialogController.$inject = ['$timeout', '$scope', 'AnneeScolaire', '$uibModalInstance', 'entity', 'GLOBAL_CONSTANTS'];

    function AnneeScolaireDialogController($timeout, $scope, AnneeScolaire, $uibModalInstance, entity, GLOBAL_CONSTANTS) {
        var vm = this;

        vm.anneeScolaire = entity
        vm.openCalendar = openCalendar;
        vm.save = save;
        vm.clear = clear;
        vm.dateFormat = GLOBAL_CONSTANTS.DATE_FORMAT;
        vm.datePickerOpenStatus = {};
        if (vm.anneeScolaire.dateDebut != null) {
            vm.anneeScolaire.dateDebut = new Date(vm.anneeScolaire.dateDebut);
        }
        if (vm.anneeScolaire.dateFin != null) {
            vm.anneeScolaire.dateFin = new Date(vm.anneeScolaire.dateFin);
        }
        $timeout(function () {
            angular.element('.form-group:eq(1) input').focus();
        });

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        function save() {
            vm.isSaving = true;
            if (vm.anneeScolaire.id !== null) {
                AnneeScolaire.update(vm.anneeScolaire, onSaveSuccess, onSaveError);
            } else {
                AnneeScolaire.save(vm.anneeScolaire, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess(result) {
            $scope.$emit('josdaviApp:anneeScolaireUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError() {
            vm.isSaving = false;
        }

        vm.datePickerOpenStatus.dateDebut = false;
        vm.datePickerOpenStatus.dateFin = false;

        function openCalendar(date) {
            vm.datePickerOpenStatus[date] = true;
        }
    }
})();