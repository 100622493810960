(function() {
    'use strict';

    angular
        .module('josdaviApp', [
            'ngStorage',
            'tmh.dynamicLocale',
            'pascalprecht.translate',
            'ngResource',
            'ngCookies',
            'ngAria',
            'ngCacheBuster',
            'ngFileUpload',
            'ui.bootstrap',
            'ui.bootstrap.datetimepicker',
            'ui.router',
            'infinite-scroll',
            'BlurAdmin',
            'restangular',
            'ui-notification',
            'smoothScroll',
            'angular-loading-bar',
            'ds.objectDiff',
            // jhipster-needle-angularjs-add-module JHipster will add new module here
        ])
        .constant('_', window._) // Ajout de la librairie lodash
        .run(run);

    run.$inject = ['$rootScope', 'stateHandler', 'translationHandler', 'Notification'];

    function run($rootScope, stateHandler, translationHandler, Notification) {
        stateHandler.initialize();
        translationHandler.initialize();
        $rootScope.$on('josdaviApp.httpError', function(event, err) {
            if (err && err.data) {
                if (err.status === 500) {
                    Notification.error({
                        delay:10000,
                        replaceMessage: true,
                        title: "Erreur",
                        message: "Nous avons rencontré une erreur interne, si cela persiste et vous empêche de travailler, veuillez contacter l'équipe technique."
                    });
                } else if(err.status === 401) {
                    Notification.error({
                        delay:10000,
                        replaceMessage: true,                        
                        title: "Erreur",
                        message: "Vous n'avez pas accès à ces ressources"
                    });
                }else {
                    Notification.error({
                        delay:10000,
                        replaceMessage: true,                        
                        title: "Erreur",
                        message: err.data.message
                    });
                }
            }
        });
        // use in views, ng-repeat="x in _.range(3)"
        $rootScope._ = window._;
    }
})();