(function () {
    'use strict';

    angular
        .module('josdaviApp')
        .controller('EleveDetailController', EleveDetailController);

    EleveDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'DataUtils', 'entity', 'Eleve', 'Abonnement', 'Bulletin', 'InfosEleve', 'Note', 'Inscription', 'User', 'Parent', 'Etablissement', 'AbscenceEleve', 'Evaluation'];

    function EleveDetailController($scope, $rootScope, $stateParams, previousState, DataUtils, entity, Eleve, Abonnement, Bulletin, InfosEleve, Note, Inscription, User, Parent, Etablissement, AbscenceEleve, Evaluation) {
        var vm = this;

        vm.eleve = entity;
        vm.previousState = previousState.name;
        vm.byteSize = DataUtils.byteSize;
        vm.openFile = DataUtils.openFile;

        var unsubscribe = $rootScope.$on('josdaviApp:eleveUpdate', function (event, result) {
            vm.eleve = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
