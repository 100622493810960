(function () {
    'use strict';

    angular
        .module('josdaviApp')
        .controller('AnneeScolaireController', AnneeScolaireController);

    AnneeScolaireController.$inject = ['$scope', '$state', 'AnneeScolaire', 'AnneeScolaireSearch', 'TableService'];

    function AnneeScolaireController($scope, $state, AnneeScolaire, AnneeScolaireSearch, TableService) {

        var vm = this;
        var addItemData = {
            templateUrl: 'app/entities/annee-scolaire/annee-scolaire-dialog.html',
            controller: 'AnneeScolaireDialogController',
            controllerAs: 'vm',
            backdrop: 'static',
            size: 'md',
            resolve: {
                entity: function () {
                    return {
                        nom: null,
                        dateDebut: new Date(),
                        dateFin: null,
                        cloturee: false,
                        id: null
                    };
                }
            }
        };


        /**
         *Modification d'un élément
         */
        var editItemData = {
            templateUrl: 'app/entities/annee-scolaire/annee-scolaire-dialog.html',
            controller: 'AnneeScolaireDialogController',
            controllerAs: 'vm',
            backdrop: 'static',
            size: 'md',
            resolve: {
                entity: null
            }
        };

        /**
         * Suppresion d'un élément
         */
        var deleteItemData = {
            templateUrl: 'app/entities/annee-scolaire/annee-scolaire-delete-dialog.html',
            controller: 'AnneeScolaireDeleteController',
            controllerAs: 'vm',
            size: 'sm',
            resolve: {
                entity: null
            }
        };

        var dialogData = {
            addData: addItemData,
            editData: editItemData,
            deleteData: deleteItemData
        };
        var entitySearchParams = function () {
            return {};
        };

        var entityParams = function () {
            return {};
        };

        var datas = {
            scope: $scope,
            vm: vm,
            entity: AnneeScolaire,
            entitySearch: AnneeScolaire,
            dialogData: dialogData,
            entitySearchParams: entitySearchParams,
            entityParams: entityParams
        };
        TableService.init(datas);
    }
})();