(function() {
    'use strict';

    angular
        .module('josdaviApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider.state('admin', {
            abstract: true,
            parent: 'app',
            title: 'Administration',
            data: {
                authorities: ['ROLE_ADMIN']
            }
        })
    }
})();