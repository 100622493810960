(function() {
    'use strict';

    angular
        .module('josdaviApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider.state('app', {
            abstract: true,
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('global');
                }]
            }
        }).state('parameters', {
            abstract: true,
            parent: 'app',
            title:'Paramètres',          
            sidebarMeta: {
                icon: 'fa fa-cogs',
                order: 200
            },
            data: {
                authorities: ['ROLE_RESPONSABLE']
            }
        });
    }
})();
