(function () {
    'use strict';

    angular
        .module('josdaviApp')
        .controller('RapportController', RapportController);

    RapportController.$inject = ['GLOBAL_CONSTANTS', 'Etablissement', 'Eleve', '$rootScope', '$http', 'baConfig', '$scope', 'Principal', 'LoginService'];

    function RapportController(GLOBAL_CONSTANTS, Etablissement, Eleve, $rootScope, $http, baConfig, $scope, Principal, LoginService) {
        var vm = this;
        vm.account = null;
        vm.isAuthenticated = null;
        vm.login = LoginService.open;
        vm.showLoginDialog = showLoginDialog;
        vm.loadDatas = loadDatas;
        vm.recupererEleves = recupererEleves;
        vm.options = {};

        vm.datasEPE = [];
        vm.datasCPE = [];
        vm.datasIPT = [];
        vm.datasIPA = [];
        vm.datasIPE = [];

        vm.labelsEPE = [];
        vm.labelsCPE = [];
        vm.labelsIPT = [];
        vm.labelsIPA = [];
        vm.labelsIPE = [];
        vm.seriesIPA = [];
        vm.seriesIPE = [];
        vm.estUtilisateurSimple = true;
        vm.isLoading = false;
        vm.tab = 0;
        vm.etablissements = [];
        vm.eleves = [];

        $scope.$watch(angular.bind(vm.tab, function () {
            return vm.tab;
        }), function (d) {
            console.log('swtching tabs $scope', d);
            recupererEleves(d);
        });

        function recupererEleves(d) {
            if (d === 1) {
                vm.isLoading = true;
                Eleve.query({filtre: 'abonnes', etablissementId: vm.etablissementId}, function (items) {
                    vm.eleves = items;
                    vm.isLoading = false;
                });
            } else if (d === 2) {
                vm.isLoading = true;
                Eleve.query({filtre: 'nonabonnes', etablissementId: vm.etablissementId}, function (items) {
                    vm.eleves = items;
                    vm.isLoading = false;
                });
            }
        }

        $scope.$on('authenticationSuccess', function () {
            getAccount();
        });
        getAccount();

        function getAccount() {
            Principal.identity().then(function (account) {
                if (account !== null && account !== undefined) {
                    vm.estUtilisateurSimple = account.authorities.indexOf('ROLE_ASSISTANT') !== -1 || account.authorities.indexOf('ROLE_RESPONSABLE_ETABLISSEMENT') !== -1;
                    vm.isAuthenticated = Principal.isAuthenticated;
                    vm.loadDatas();
                }
                vm.showLoginDialog();
            });
        }

        function showLoginDialog() {
            if (!Principal.isAuthenticated()) {
                vm.login();
            }
        }

        function loadDatas() {
            /*$http.get("api/reporting/stats?type=cpe").then(
                function (res) {
                    vm.datasCPE = res.data.data;
                    vm.labelsCPE = res.data.names;
                });
            $http.get("api/reporting/stats?type=epe").then(
                function (res) {
                    vm.datasEPE = res.data.data;
                    vm.labelsEPE = res.data.names;
                });
            $http.get("api/reporting/stats?type=ipt").then(
                function (res) {
                    vm.datasIPT = res.data.data;
                    vm.labelsIPT = res.data.names;
                });
            $http.get("api/reporting/stats?type=ipa").then(
                function (res) {
                    vm.datasIPA = res.data.datas;
                    vm.labelsIPA = res.data.names;
                    vm.seriesIPA = res.data.series;
                });*/
            $http.get("api/reporting/stats?type=ipe").then(
                function (res) {
                    vm.datasIPE = res.data.datas;
                    vm.labelsIPE = res.data.names;
                    vm.seriesIPE = res.data.series;
                });
        }

        Etablissement.query({
            size: GLOBAL_CONSTANTS.MAX_ITEMS_PER_PAGE,
            sort: 'nom,asc'
        }, function (dts) {
            vm.etablissements = dts;
        });

        $scope.$watch("vm.etablissementId", function (id) {
            vm.etablissementId = id || null;
            if (id !== null) {
                vm.recupererEleves(1);
            }
        });
    }
})();
