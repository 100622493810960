(function () {
    'use strict';

    angular
        .module('josdaviApp')
        .controller('EmploieTempsDeleteController', EmploieTempsDeleteController);

    EmploieTempsDeleteController.$inject = ['$uibModalInstance', 'entity', 'EmploieTemps'];

    function EmploieTempsDeleteController($uibModalInstance, entity, EmploieTemps) {
        var vm = this;

        vm.emploieTemps = entity;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete(id) {
            EmploieTemps.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        }
    }
})();
