(function() {
    'use strict';

    angular
        .module('josdaviApp')
        .controller('NiveauController', NiveauController);

    NiveauController.$inject = ['$scope', '$state', 'Niveau',  'TableService'];

    function NiveauController($scope, $state, Niveau, TableService) {

        var vm = this;
        var addItemData = {
            templateUrl: 'app/entities/niveau/niveau-dialog.html',
            controller: 'NiveauDialogController',
            controllerAs: 'vm',
            backdrop: 'static',
            size: 'sm',
            resolve: {
                entity: function() {
                    return {
                        nom: null,
                        id: null
                    };
                }
            }
        };



        /**
         *Modification d'un élément
         */
        var editItemData = {
            templateUrl: 'app/entities/niveau/niveau-dialog.html',
            controller: 'NiveauDialogController',
            controllerAs: 'vm',
            backdrop: 'static',
            size: 'sm',
            resolve: {
                entity: null
            }
        };

        /**
         * Suppresion d'un élément
         */
        var deleteItemData = {
            templateUrl: 'app/entities/niveau/niveau-delete-dialog.html',
            controller: 'NiveauDeleteController',
            controllerAs: 'vm',
            size: 'sm',
            resolve: {
                entity: null
            }
        };

        var dialogData = {
            addData: addItemData,
            editData: editItemData,
            deleteData: deleteItemData
        };
        var entitySearchParams = function() {
            return {

            };
        };

        var entityParams = function() {
            return {

            };
        };

        var datas = {
            scope: $scope,
            vm: vm,
            entity: Niveau,
            entitySearch: Niveau,
            dialogData: dialogData,
            entitySearchParams: entitySearchParams,
            entityParams: entityParams
        };
        TableService.init(datas);
    }
})();
