(function() {
    'use strict';

    angular
        .module('BlurAdmin.theme.components')
        .controller('PageTopCtrl', PageTopCtrl);

    PageTopCtrl.$inject = ['$state','$scope', 'Auth', 'Principal', "Account", 'ProfileService', 'LoginService'];

    function PageTopCtrl($state,$scope, Auth, Principal, Account, ProfileService, LoginService) {
        var vm = this;

        ProfileService.getProfileInfo().then(function(response) {
            vm.inProduction = response.inProduction;
            vm.swaggerEnabled = response.swaggerEnabled;
        });
        vm.account=null;

        vm.login = login;
        vm.logout = logout;
        vm.$state = $state;
        vm.checkAccount=checkAccount;
        vm.checkAccount();       

        $scope.$on('onLogoutSuccess', function(){
            vm.isAuthenticated = false;
           // vm.account=null;
        });        

        $scope.$on('onLoginSuccess', function(){
            vm.isAuthenticated = true;
            vm.checkAccount();
            console.log(vm.account);
        });        

        function login() {
            LoginService.open();
        }

        function logout() {
            Auth.logout();
            $state.go('home');
        }

        function checkAccount(){
            Account.get().$promise
            .then(function(account) {
                vm.isAuthenticated = (angular.isDefined(account) && account !==null && account.data!==null);
                vm.account=account;    
            })
            .catch(function() {
                vm.isAuthenticated = false;
            });
        }
    }
})();