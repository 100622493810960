(function () {
    'use strict';

    angular
        .module('josdaviApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('abonnement', {
                parent: 'entity',
                url: '/abonnements',
                title: 'Abonnements',
                sidebarMeta: {
                    icon: 'fa fa-usd',
                    order: 2
                },
                data: {
                    authorities: ['ROLE_RESPONSABLE_INSCRIPTION','ROLE_RESPONSABLE','ROLE_ADMIN'],
                    pageTitle: 'josdaviApp.abonnement.home.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/abonnement/abonnements.html',
                        controller: 'AbonnementController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('abonnement');
                        $translatePartialLoader.addPart('etatAbonnement');
                        $translatePartialLoader.addPart('statutArchivage');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }]
                }
            });
    }

})();
