(function () {
    'use strict';

    angular
        .module('josdaviApp')
        .controller('EtablissementDialogController', EtablissementDialogController);

    EtablissementDialogController.$inject = ['GLOBAL_CONSTANTS','User','$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'Etablissement', 'Ville'];

    function EtablissementDialogController(GLOBAL_CONSTANTS,User,$timeout, $scope, $stateParams, $uibModalInstance, entity, Etablissement, Ville) {
        var vm = this;

        vm.etablissement = entity;
        vm.clear = clear;
        vm.save = save;
        vm.villes = Ville.query({size:100});
        vm.loadUsers=loadUsers;
        vm.users=[];
        vm.userColumns = [{
            name: 'login',
            title: 'Login'
        }];

        $scope.$on("onLoadUsers", function () {
            console.log("chargements des utilisateurs");
            vm.loadUsers();
        });

        loadUsers();
        function loadUsers() {
            vm.users=User.query({
                size: GLOBAL_CONSTANTS.MAX_ITEMS_PER_PAGE,
                sort: 'login,asc'
            });
        }

        if(vm.etablissement.superviseurId!=null){
            User.query({
                size: GLOBAL_CONSTANTS.MAX_ITEMS_PER_PAGE,
                sort: 'login,asc'
            },function (datas) {
                vm.users=datas;
                vm.superviseur = _.find(vm.users, {
                    'id': vm.etablissement.superviseurId
                });
            });
        }

        $scope.$watch("vm.etablissement.superviseurId", function (id) {
            console.log("recherche de l'utilisateur");
            if (id !== undefined && id !== null) {
                vm.superviseur = _.find(vm.users, {
                    'id': id
                });
            }
        });

        $timeout(function () {
            angular.element("input[type='text']:eq(0)").focus();
        });

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        function save() {
            vm.isSaving = true;
            if (vm.etablissement.id !== null) {
                Etablissement.update(vm.etablissement, onSaveSuccess, onSaveError);
            } else {
                Etablissement.save(vm.etablissement, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess(result) {
            $scope.$emit('josdaviApp:etablissementUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError() {
            vm.isSaving = false;
        }
    }
})();
