(function() {
    'use strict';

    angular
        .module('josdaviApp')
        .controller('ImportationDeleteController',ImportationDeleteController);

    ImportationDeleteController.$inject = ['$uibModalInstance', 'entity', 'Importation'];

    function ImportationDeleteController($uibModalInstance, entity, Importation) {
        var vm = this;

        vm.importation = entity;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete (id) {
            Importation.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        }
    }
})();
