(function () {
    'use strict';

    angular
        .module('josdaviApp')
        .controller('EleveController', EleveController);

    EleveController.$inject = ['$uibModal', 'Etablissement', 'EtablissementClasses', 'GLOBAL_CONSTANTS', 'ElevesByClasse', '$scope', '$state', 'Eleve', 'TableService'];

    function EleveController($uibModal, Etablissement, EtablissementClasses, GLOBAL_CONSTANTS, ElevesByClasse, $scope, $state, Eleve, TableService) {

        var vm = this;
        vm.loadEtablissements = loadEtablissements;
        vm.loadClasses = loadClasses;
        vm.viewParent = viewParent;
        vm.editParent = editParent;
        vm.searchEleves = searchEleves;
        vm.abonner = abonner;
        vm.filtrer = false;
        vm.searchItem = {
            classeId: null,
            etablissementId: null,
            nom: null,
            prenom: null,
            code: null,
            telephone: null
        };
        vm.loadEtablissements();

        vm.initFiltre = function () {
            vm.filtrer = !vm.filtrer;
            if (!vm.filtrer) {
                initDatas();
                vm.searchItem = {
                    classeId: null,
                    etablissementId: null,
                    nom: null,
                    prenom: null,
                    code: null,
                    telephone: null
                };
                var entitySearchParams = function () {
                    return {};
                };

                var entityParams = function () {
                    return {};
                };

                var datas = {
                    scope: $scope,
                    vm: vm,
                    entity: Eleve,
                    entitySearch: Eleve,
                    dialogData: {},
                    entitySearchParams: entitySearchParams,
                    entityParams: entityParams
                };
                TableService.init(datas);
            }
        };

        function searchEleves() {
            var addItemData = {
                templateUrl: 'app/entities/eleve/eleve-dialog.html',
                controller: 'EleveDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'md',
                resolve: {
                    entity: function () {
                        return {
                            code: null,
                            numero: null,
                            nom: null,
                            prenom: null,
                            dateNaissance: null,
                            sexe: null,
                            statut: null,
                            photo: null,
                            photoContentType: null,
                            id: null
                        };
                    }
                }
            };


            /*
             *Modification d'un élément
             */
            var editItemData = {
                templateUrl: 'app/entities/eleve/eleve-dialog.html',
                controller: 'EleveDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'md',
                resolve: {
                    entity: null
                }
            };

            /*
             * Suppresion d'un élément
             */
            var deleteItemData = {
                templateUrl: 'app/entities/eleve/eleve-delete-dialog.html',
                controller: 'EleveDeleteController',
                controllerAs: 'vm',
                size: 'sm',
                resolve: {
                    entity: null
                }
            };

            var dialogData = {
                addData: addItemData,
                editData: editItemData,
                deleteData: deleteItemData
            };
            var entitySearchParams = function () {
                return {};
            };

            var entityParams = function () {
                return {
                    classeId: vm.searchItem.classeId,
                    etablissementId: vm.searchItem.etablissementId,
                    nom: vm.searchItem.nom,
                    prenom: vm.searchItem.prenom,
                    code: vm.searchItem.code,
                    telephone: vm.searchItem.telephone
                };
            };

            var datas = {
                scope: $scope,
                vm: vm,
                entity: ElevesByClasse,
                entitySearch: ElevesByClasse,
                dialogData: dialogData,
                entitySearchParams: entitySearchParams,
                entityParams: entityParams
            };
            TableService.init(datas);

        }

        $scope.$watch("vm.searchItem.etablissementId", function (id) {
            if (id !== undefined && id !== null) {
                vm.loadClasses(id);
            }
        });

        function loadClasses(etablissementId) {
            EtablissementClasses.query({
                id: etablissementId,
                size: GLOBAL_CONSTANTS.MAX_ITEMS_PER_PAGE,
                sort: 'nom,asc'
            }, function (res) {
                vm.classes = res;
            });
        }


        $scope.$watch("vm.classeId", function (id) {
            if (id !== undefined && id !== null) {

                var addItemData = {
                    templateUrl: 'app/entities/eleve/eleve-dialog.html',
                    controller: 'EleveDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'md',
                    resolve: {
                        entity: function () {
                            return {
                                code: null,
                                numero: null,
                                nom: null,
                                prenom: null,
                                dateNaissance: null,
                                sexe: null,
                                statut: null,
                                photo: null,
                                photoContentType: null,
                                id: null
                            };
                        }
                    }
                };


                /*
                 *Modification d'un élément
                 */
                var editItemData = {
                    templateUrl: 'app/entities/eleve/eleve-dialog.html',
                    controller: 'EleveDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'md',
                    resolve: {
                        entity: null
                    }
                };

                /*
                 * Suppresion d'un élément
                 */
                var deleteItemData = {
                    templateUrl: 'app/entities/eleve/eleve-delete-dialog.html',
                    controller: 'EleveDeleteController',
                    controllerAs: 'vm',
                    size: 'sm',
                    resolve: {
                        entity: null
                    }
                };

                var dialogData = {
                    addData: addItemData,
                    editData: editItemData,
                    deleteData: deleteItemData
                };
                var entitySearchParams = function () {
                    return {};
                };

                var entityParams = function () {
                    return {
                        id: id
                    };
                };

                var datas = {
                    scope: $scope,
                    vm: vm,
                    entity: ElevesByClasse,
                    entitySearch: ElevesByClasse,
                    dialogData: dialogData,
                    entitySearchParams: entitySearchParams,
                    entityParams: entityParams
                };
                TableService.init(datas);
            }
        });


        function loadEtablissements() {
            vm.etablissements = Etablissement.query({
                size: GLOBAL_CONSTANTS.MAX_ITEMS_PER_PAGE,
                sort: 'nom,asc'
            });
        }

        function viewParent($event, eleve) {
            $event.stopPropagation();
            var parentList = {
                templateUrl: 'app/entities/parent/parents-simple-list.html',
                controller: function () {
                    var vm = this;
                    vm.parents = eleve.parents;
                },
                controllerAs: 'vm',
                size: 'lg'
            };
            $uibModal.open(parentList).result.then(function (datas) {
            }, function () {
            });
        }

        function editParent($event, eleve) {
            $event.stopPropagation();
            var parentEdit = {
                templateUrl: 'app/entities/parent/parent-dialog.html',
                controller: 'ParentDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'md',
                resolve: {
                    entity: eleve.parents[0]
                }
            };
            $uibModal.open(parentEdit).result.then(function (datas) {
            }, function () {
            });
        }

        function abonner($event, eleve) {
            $event.stopPropagation();
            var addItemData = {
                templateUrl: 'app/entities/abonnement/abonnement-dialog.html',
                controller: 'AbonnementDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'md',
                resolve: {
                    entity: function () {
                        return {
                            numero: null,
                            dateDebut: null,
                            dateFin: null,
                            cloturee: false,
                            montant: null,
                            eleveId: eleve.id,
                            etat: "ACTIF",
                            statut: 'ACTIF',
                            id: null
                        };
                    }
                }
            };
            $uibModal.open(addItemData).result.then(function (datas) {
            }, function () {
                console.error("Erreur d'abonnement");
            });
        }


        function initDatas() {
            var addItemData = {
                templateUrl: 'app/entities/eleve/eleve-dialog.html',
                controller: 'EleveDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'md',
                resolve: {
                    entity: function () {
                        return {
                            code: null,
                            numero: null,
                            nom: null,
                            prenom: null,
                            dateNaissance: null,
                            sexe: null,
                            statut: null,
                            photo: null,
                            photoContentType: null,
                            id: null
                        };
                    }
                }
            };


            /**
             *Modification d'un élément
             */
            var editItemData = {
                templateUrl: 'app/entities/eleve/eleve-dialog.html',
                controller: 'EleveDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'md',
                resolve: {
                    entity: null
                }
            };

            /**
             * Suppresion d'un élément
             */
            var deleteItemData = {
                templateUrl: 'app/entities/eleve/eleve-delete-dialog.html',
                controller: 'EleveDeleteController',
                controllerAs: 'vm',
                size: 'sm',
                resolve: {
                    entity: null
                }
            };

            var dialogData = {
                addData: addItemData,
                editData: editItemData,
                deleteData: deleteItemData
            };
            var entitySearchParams = function () {
                return {};
            };

            var entityParams = function () {
                return {};
            };

            var datas = {
                scope: $scope,
                vm: vm,
                entity: Eleve,
                entitySearch: Eleve,
                dialogData: dialogData,
                entitySearchParams: entitySearchParams,
                entityParams: entityParams
            };
            TableService.init(datas);
        }

        initDatas();

    }
})();
