/**
 * @author v.lugovsky
 * created on 17.12.2015
 */
(function () {
  'use strict';

  profilePicture.$inject = ["layoutPaths"];
  angular.module('BlurAdmin.theme')
      .filter('profilePicture', profilePicture);

  /** @ngInject */
  function profilePicture(layoutPaths) {
    return function(input, ext) {
      ext = ext || 'png';
      if(angular.isUndefined(input)||input===null){
        return layoutPaths.images.profile+"no-photo.png";
      }else{
        return layoutPaths.images.profile + input + '.' + ext;
      }
    };
  }

})();
