(function() {
    'use strict';

    angular
        .module('josdaviApp')
        .controller('ParentDialogController', ParentDialogController);

    ParentDialogController.$inject = ['GLOBAL_CONSTANTS', 'Langue', '$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'Parent'];

    function ParentDialogController (GLOBAL_CONSTANTS, Langue, $timeout, $scope, $stateParams, $uibModalInstance, entity, Parent) {
        var vm = this;

        vm.parent = entity;
        vm.clear = clear;
        vm.save = save;
        /*Charger la liste des niveaux de classes*/
        Langue.query({
            size: GLOBAL_CONSTANTS.MAX_ITEMS_PER_PAGE,
            sort: 'nom,asc'
        }, function (res) {
            vm.langues = res;
        });
        if(vm.parent.id===null){
            vm.parent.typeCarte="CNIB";
        }

        $timeout(function (){
            angular.element("input[type='text']:eq(0)").focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;
            if (vm.parent.id !== null) {
                Parent.update(vm.parent, onSaveSuccess, onSaveError);
            } else {
                Parent.save(vm.parent, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('josdaviApp:parentUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }


    }
})();
