(function () {
    'use strict';

    angular
        .module('josdaviApp')
        .controller('SeanceCoursDialogController', SeanceCoursDialogController);

    SeanceCoursDialogController.$inject = ['GLOBAL_CONSTANTS', 'Restangular', 'Etablissement', '$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'SeanceCours', 'AnneeScolaire', 'Classe', 'EmploieTemps', 'Matiere'];

    function SeanceCoursDialogController(GLOBAL_CONSTANTS, Restangular, Etablissement, $timeout, $scope, $stateParams, $uibModalInstance, entity, SeanceCours, AnneeScolaire, Classe, EmploieTemps, Matiere) {
        var vm = this;

        vm.seanceCours = entity;
        vm.clear = clear;
        vm.save = save;
        vm.anneescolaires = AnneeScolaire.query();

        vm.matieres = Matiere.query(
            {
                size: GLOBAL_CONSTANTS.MAX_ITEMS_PER_PAGE,
                sort: 'nom,asc'
            }
        );
        vm.etablissements = Etablissement.query(
            {
                size: GLOBAL_CONSTANTS.MAX_ITEMS_PER_PAGE,
                sort: 'nom,asc'
            }
        );
        vm.HEURES = ['06', '07', '08', '09', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22'];

        vm.loadClasses = loadClasses;
        vm.loadEtablissements = loadEtablissements;

        $timeout(function () {
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        function save() {
            vm.isSaving = true;
            if (vm.seanceCours.id !== null) {
                SeanceCours.update(vm.seanceCours, onSaveSuccess, onSaveError);
            } else {
                SeanceCours.save(vm.seanceCours, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess(result) {
            $scope.$emit('josdaviApp:seanceCoursUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError() {
            vm.isSaving = false;
        }

        //Gestion de la selection de l'etablissement
        vm.etablissementColumns = [{
            name: 'numero',
            title: 'Numéro'
        }, {
            name: 'nom',
            title: 'Nom'
        }, {
            name: 'type',
            title: 'Type'
        }, {
            name: 'villeNom',
            title: 'Ville'
        }];

        $scope.$on("onLoadEtablissements", function () {
            vm.loadEtablissements();
        });

        function loadEtablissements() {
            Restangular.all('etablissements').getList().then(function (etablissements) {
                vm.etablissements = etablissements;
            });
        }

        $scope.$watch("vm.etablissementId", function (id) {
            if (id !== undefined && id !== null) {
                vm.etablissement = _.find(vm.etablissements, {
                    'id': id
                });
            }
        });

        //Gestion de la selection de la classe

        vm.classeColumns = [{
            name: 'cycle',
            title: 'cycle'
        }, {
            name: 'niveau',
            title: 'Niveau'
        }, {
            name: 'nom',
            title: 'Nom'
        }];

        $scope.$on("onLoadClasses", function () {
            vm.loadClasses();
        });

        function loadClasses() {
            if (vm.etablissement !== undefined && vm.etablissement !== null) {
                vm.etablissement.getList("classes").then(function (classes) {
                    vm.classes = classes;
                });
            }
        }

        initDatas();

        function initDatas() {
            if (vm.seanceCours.id != null) {
                Restangular.all('classes').getList().then(function (classes) {
                    vm.classe = _.find(classes, {
                        'id': vm.seanceCours.classeId
                    });

                    Restangular.all('etablissements').getList().then(function (etablissements) {
                        vm.etablissement = _.find(vm.etablissements, {
                            'id': vm.classe.etablissementId
                        });
                        vm.etablissementId = vm.etablissement.id;
                    });


                    console.log(vm.classe);
                });
            }
        }


        $scope.$watch("vm.seanceCours.classeId", function (id) {
            if (id !== undefined && id !== null) {
                vm.classe = _.find(vm.classes, {
                    'id': id
                });
            }
        });

    }
})();
