(function () {
    'use strict';

    angular
        .module('josdaviApp')
        .controller('ClasseDialogController', ClasseDialogController);

    ClasseDialogController.$inject = ['GLOBAL_CONSTANTS', '$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'Classe', 'Matiere'];

    function ClasseDialogController(GLOBAL_CONSTANTS, $timeout, $scope, $stateParams, $uibModalInstance, entity, Classe, Matiere) {
        var vm = this;

        vm.classe = entity;
        vm.clear = clear;
        vm.save = save;
        vm.niveaux = [];
        vm.matieres = Matiere.query({size: GLOBAL_CONSTANTS.MAX_ITEMS_PER_PAGE});
        //vm.etablissements = Etablissement.query({size:GLOBAL_CONSTANTS.MAX_ITEMS_PER_PAGE});
        $scope.$watch("vm.classe.cycle", function (cycle) {
            if (angular.isDefined(cycle)) {
                if (cycle === "PREMIER") {
                    vm.niveaux = [{
                        nom: "CP1"
                    }, {
                        nom: "CP2"
                    }, {
                        nom: "CE1"
                    }, {
                        nom: "CE2"
                    }, {
                        nom: "CM1"
                    }, {
                        nom: "CM2"
                    }, {
                        nom: "SIXIEME"
                    }, {
                        nom: "CINQUIEME"
                    }, {
                        nom: "QUATRIEME"
                    }, {
                        nom: "TROISIEME"
                    }, {
                        nom: "CAP"
                    }, {
                        nom: "CAP1"
                    }, {
                        nom: "CAP2"
                    }, {
                        nom: "CAP3"
                    }, {
                        nom: "AP"
                    }];
                } else {
                    vm.niveaux = [{
                        nom: "SECONDE_F4"
                    },{
                        nom: "SECONDE_AB3"
                    },{
                        nom: "SECONDE"
                    }, {
                        nom: "PREMIERE"
                    }, {
                        nom: "TERMINALE"
                    }, {
                        nom: "BEP1"
                    }, {
                        nom: "BEP2"
                    }, {
                        nom: "BACPRO1"
                    }, {
                        nom: "BACPRO2"
                    }, {
                        nom: "PREMIERE_F4"
                    }, {
                        nom: "PREMIERE_G1"
                    }, {
                        nom: "PREMIERE_G2"
                    }, {
                        nom: "TERMINAL_F4"
                    }, {
                        nom: "TERMINAL_G1"
                    }, {
                        nom: "TERMINAL_G2"
                    }];

                }
            } else {
                vm.niveaux = [];
            }
        });
        $timeout(function () {
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        function save() {
            vm.isSaving = true;
            if (vm.classe.id !== null) {
                Classe.update(vm.classe, onSaveSuccess, onSaveError);
            } else {
                Classe.save(vm.classe, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess(result) {
            $scope.$emit('josdaviApp:classeUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError() {
            vm.isSaving = false;
        }


    }
})();
