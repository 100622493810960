(function() {
    'use strict';

    angular
        .module('josdaviApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('parameters.niveau', {
            parent: 'parameters',
            url: '/niveaux',
            title: 'Niveaux',
            sidebarMeta: {
                icon: 'glyphicon glyphicon-level-up',
                order: 7
            },
            data: {
                authorities: ['ROLE_ADMIN','ROLE_RESPONSABLE'],
                pageTitle: 'josdaviApp.niveau.home.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/niveau/niveau.html',
                    controller: 'NiveauController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('niveau');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        });    }

})();
