(function() {
    'use strict';

    angular
        .module('josdaviApp')
        .controller('PeriodeController', PeriodeController);

    PeriodeController.$inject = ['$stateParams','$scope', 'TableService', 'PeriodesOfAnneeScolaire', 'PeriodeSearch'];

    function PeriodeController($stateParams,$scope, TableService, PeriodesOfAnneeScolaire, PeriodeSearch) {

        var vm = this;
        console.log("periodes....................");
        vm.anneeScolaire=$stateParams.anneeScolaire;
        console.log(vm.anneeScolaire);
        
        var addItemData = {
            templateUrl: 'app/entities/periode/periode-dialog.html',
            controller: 'PeriodeDialogController',
            controllerAs: 'vm',
            backdrop: 'static',
            size: 'md',
            resolve: {
                entity: function() {
                    return {
                        nom: null,
                        dateDebut: null,
                        dateFin: null,
                        cloturee: null,
                        anneeId:vm.anneeScolaire.id,
                        anneNom:vm.anneeScolaire.nom,
                        id: null
                    };
                }
            }
        };



        /**
         *Modification d'un élément
         */
        var editItemData = {
            templateUrl: 'app/entities/periode/periode-dialog.html',
            controller: 'PeriodeDialogController',
            controllerAs: 'vm',
            backdrop: 'static',
            size: 'md',
            resolve: {
                entity: null
            }
        };

        /**
         * Suppresion d'un élément
         */
        var deleteItemData = {
            templateUrl: 'app/entities/periode/periode-delete-dialog.html',
            controller: 'PeriodeDeleteController',
            controllerAs: 'vm',
            size: 'sm',
            resolve: {
                entity: null
            }
        };

        var dialogData = {
            addData: addItemData,
            editData: editItemData,
            deleteData: deleteItemData
        };
        var entitySearchParams = function() {
            return {

            };
        };

        var entityParams = function() {
            return {
                id:vm.anneeScolaire.id
            };
        };

        var datas = {
            scope: $scope,
            vm: vm,
            entity: PeriodesOfAnneeScolaire,
            entitySearch: PeriodesOfAnneeScolaire,
            dialogData: dialogData,
            entitySearchParams: entitySearchParams,
            entityParams: entityParams
        };
        TableService.init(datas);
    }
})();