(function() {
    "use strict";
    /**
     * Regroupe l'ensemble des services basés sur Restangular
     * et permettant de manipuler plus aiséments des entités.
     */
    angular.module('josdaviApp')
        .factory('Etablissements',['Restangular', function(Restangular) {
            return Restangular.service('etablissements');
        }]).factory('Inscriptions',['Restangular', function(Restangular) {
            return Restangular.service('inscriptions');
        }]);
})();