(function () {
    'use strict';

    angular
        .module('josdaviApp')
        .controller('ImportationController', ImportationController);

    ImportationController.$inject = ["Notification", '$http', '$scope', '$state', 'Importation', 'TableService', 'DataUtils'];

    function ImportationController(Notification, $http, $scope, $state, Importation, TableService, DataUtils) {

        var vm = this;
        vm.openFile = DataUtils.openFile;
        vm.isSaving = false;

        vm.importAll = function () {
            vm.isSaving = true;
            $http.post('api/importations/demarrer', {}).then(function () {
                console.log("Importation reussi");
                Notification.success("Importation effectuée avec succès");
                vm.isSaving = false;
                vm.loadAll();
            }, function () {
                vm.isSaving = false;
                console.error("Importation echouée");
            });
        };

        vm.import = function ($event, item) {
            vm.isSaving = true;
            $http.post('api/importations/demarrer/' + item.id, {}).then(function () {
                console.log("Importation reussi");
                Notification.success("Importation effectuée avec succès");
                vm.isSaving = false;
                vm.loadAll();
            }, function () {
                console.error("Importation echouée");
                vm.isSaving = false;
            });
        };

        var addItemData = {
            templateUrl: 'app/entities/importation/importation-dialog.html',
            controller: 'ImportationDialogController',
            controllerAs: 'vm',
            backdrop: 'static',
            size: 'md',
            resolve: {
                entity: function () {
                    return {
                        dateDerniereExecution: null,
                        fichier: null,
                        titre: null,
                        executionReussi: false,
                        id: null
                    };
                }
            }
        };


        /**
         *Modification d'un élément
         */
        var editItemData = {
            templateUrl: 'app/entities/importation/importation-dialog.html',
            controller: 'ImportationDialogController',
            controllerAs: 'vm',
            backdrop: 'static',
            size: 'md',
            resolve: {
                entity: null
            }
        };

        /**
         * Suppresion d'un élément
         */
        var deleteItemData = {
            templateUrl: 'app/entities/importation/importation-delete-dialog.html',
            controller: 'ImportationDeleteController',
            controllerAs: 'vm',
            size: 'sm',
            resolve: {
                entity: null
            }
        };

        var dialogData = {
            addData: addItemData,
            editData: editItemData,
            deleteData: deleteItemData
        };
        var entitySearchParams = function () {
            return {};
        };

        var entityParams = function () {
            return {};
        };

        var datas = {
            scope: $scope,
            vm: vm,
            entity: Importation,
            entitySearch: Importation,
            dialogData: dialogData,
            entitySearchParams: entitySearchParams,
            entityParams: entityParams
        };
        TableService.init(datas);
    }
})();
