(function() {
    'use strict';

    angular
        .module('josdaviApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('parameters.langue', {
                parent: 'parameters',
                url: '/langues',
                title: 'Langues',
                sidebarMeta: {
                    icon: 'glyphicon glyphicon-tower',
                    order: 8
                },
                data: {
                    authorities: ['ROLE_ADMIN','ROLE_RESPONSABLE'],
                    pageTitle: 'josdaviApp.langue.home.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/langue/langues.html',
                        controller: 'LangueController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('langue');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }]
                }
            });
        }

})();
