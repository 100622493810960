(function() {
    'use strict';

    angular
        .module('josdaviApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('user-extra', {
                parent: 'entity',
                url: '/assistants',
                title: 'Utilisateurs',
                sidebarMeta: {
                    icon: 'fa fa-eye',
                    order: 3
                },
                data: {
                    authorities: ['ROLE_ADMIN','ROLE_RESPONSABLE'],
                    pageTitle: 'josdaviApp.userExtra.home.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/user-extra/user-extras.html',
                        controller: 'UserExtraController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('global');
                        $translatePartialLoader.addPart('user-management');
                        $translatePartialLoader.addPart('userExtra');
                        $translatePartialLoader.addPart('typeCarte');
                        return $translate.refresh();
                    }]
                }
            });
    }

})();
