(function () {
    'use strict';

    angular
        .module('josdaviApp')
        .controller('AbonnementDeleteController', AbonnementDeleteController);

    AbonnementDeleteController.$inject = ['$uibModalInstance', 'entity', 'Abonnement'];

    function AbonnementDeleteController($uibModalInstance, entity, Abonnement) {
        var vm = this;

        vm.abonnement = entity;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete(id) {
            Abonnement.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        }
    }
})();
