(function () {
    var log = console.log;
    var err = console.error;
    var warn = console.warn;
    console.log = function (msg) {
        //On désactive tous les log
        //log.apply(console, arguments);
    };

    console.warn = function (msg) {
        //On désactive tous les log
        //warn.apply(console, arguments);
    };

    console.err = function (msg) {
        //On désactive tous les log
        //err.apply(console, arguments);
    };

})();
