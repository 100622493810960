(function () {
    'use strict';

    angular
        .module('josdaviApp')
        .controller('InfoAdminController', InfosAdminController);

    InfosAdminController.$inject = ['GLOBAL_CONSTANTS', "$window", 'DataUtils', '$http', '$scope', 'Notification', 'EtablissementClasses', "Etablissement"];

    function InfosAdminController(GLOBAL_CONSTANTS, $window, DataUtils, $http, $scope, Notification, EtablissementClasses, Etablissement) {
        var vm = this;
        vm.isSaving = false;
        vm.datePickerOpenStatus = {};
        vm.dateFormat = GLOBAL_CONSTANTS.DATE_FORMAT;
        vm.openCalendar = openCalendar;
        vm.abonnement = {};
        vm.datePickerOpenStatus.dateDebut = false;
        vm.datePickerOpenStatus.dateFin = false;
        vm.etablissements = Etablissement.query({
            size: GLOBAL_CONSTANTS.MAX_ITEMS_PER_PAGE,
            sort: 'nom,asc'
        });
        vm.classes = [];
        vm.reseaux = GLOBAL_CONSTANTS.RESEAUX;

        vm.send = send;
        vm.clear = clear;
        vm.hasError = hasError;
        vm.setFichier = setFichier;
        vm.sendMoyenne = sendMoyenne;
        vm.clearMoyenne = clearMoyenne;
        vm.deleteAllEleve = deleteAllEleve;
        vm.exportAllEleves = exportAllEleves;
        vm.activateAll = activateAll;
        vm.changerCanal = changerCanal;


        vm.infos = {
            "fichier": null,
            "fichierContentType": null,
            "contacts": null,
            "contenu": null,
            "filtre": "tous",
            "reseau": "TOUS",
            "canal": "APPLICATION",
            "etablissementId": null,
            "abonnement": null,
            "classeId": null
        };
        vm.infosMoyenne = {
            "fichier": null,
            "fichierContentType": null,
            "message": null
        };

        function hasError(form, field) {
            return (form.$submitted || field.$touched) && field.$invalid;
        }

        /**
         *A la selection de l'établissement, on charge par défaut la liste
         *des classes qui y existent.
         */
        $scope.$watch(angular.bind(vm.infos, function () {
            return vm.infos.etablissementId;
        }), function (etablissementId) {
            if (angular.isDefined(etablissementId) && etablissementId !== null) {
                EtablissementClasses.query({
                    id: etablissementId,
                    size: GLOBAL_CONSTANTS.MAX_ITEMS_PER_PAGE,
                    sort: 'nom,asc'
                }, function (res) {
                    vm.classes = res;
                    vm.infos.classeId = null;
                });
            }
        });

        function send() {
            vm.isSaving = true;
            $http.post("api/infos-administration", angular.copy(vm.infos)).then(function () {
                Notification.success("Les informations ont été envoyées avec succès");
                vm.isSaving = false;
                vm.clear();
            }, function () {
                Notification.error("L'envoie du message a échoué");
                vm.isSaving = false;
            });
        }

        function activateAll() {
            vm.isSaving = true;
            vm.infos.abonnement = vm.abonnement;
            vm.infos.contenu = "Activation multiple";
            $http.post("api/abonnements/activer", angular.copy(vm.infos)).then(function () {
                Notification.success("Les comptes ont été activés avec succès");
                vm.isSaving = false;
                vm.clear();
            }, function () {
                Notification.error("L'activation a echoué !");
                vm.isSaving = false;
            });
        }

        function sendMoyenne() {
            vm.isSaving = true;
            $http.post("api/envoie/moyennes", vm.infosMoyenne).then(function () {
                Notification.success("Les moyennes ont été envoyées avec succès");
                vm.isSaving = false;
                vm.clearMoyenne();
                vm.clear();
            }, function () {
                Notification.error("L'envoie du message a échoué");
                vm.isSaving = false;
            });
        }

        function changerCanal() {
            vm.isSaving = true;
            $http.post("api/changer-canal", vm.infos).then(function () {
                Notification.success("Le canal a été mis à jour avec succès");
                vm.isSaving = false;
                vm.clear();
            }, function () {
                Notification.error("La mise à jour a échoué");
                vm.isSaving = false;
            });
        }

        function deleteAllEleve() {
            vm.isSaving = true;
            $http.delete("api/classes/" + vm.infos.classeId + "/eleves").then(function () {
                Notification.success("Tous les élèves de la classe ont été supprimés avec succès");
                vm.isSaving = false;
                vm.clear();
            }, function () {
                Notification.error("La suppression des élèves a échoué");
                vm.isSaving = false;
            });
        }

        function exportAllEleves() {
            $window.open("api/reporting/classes/" + vm.infos.classeId + "/eleves");
        }

        function clear() {
            vm.infos = {
                "contacts": null,
                "contenu": null,
                "etablissementId": null,
                "classeId": null,
                "filtre": "tous",
                "reseau": "TOUS"
            };
            vm.abonnement = {};
        }

        function clearMoyenne() {
            vm.infosMoyenne = {
                "fichier": null,
                "fichierContentType": null,
                "contenu": null
            };
        }

        function openCalendar(date) {
            vm.datePickerOpenStatus[date] = true;
        }

        function setFichier($file, infos) {
            if ($file) {
                DataUtils.toBase64($file, function (base64Data) {
                    $scope.$apply(function () {
                        infos.fichier = base64Data;
                        infos.fichierContentType = $file.type;
                    });
                });
            }
        }
    }
})();
