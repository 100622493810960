(function () {
    'use strict';

    angular
        .module('josdaviApp')
        .controller('UserExtraController', UserExtraController);

    UserExtraController.$inject = ['$http', 'User', '$uibModal', 'GLOBAL_CONSTANTS', 'Etablissement', 'TableService', '$scope', 'DataUtils', 'UserExtra'];

    function UserExtraController($http, User, $uibModal, GLOBAL_CONSTANTS, Etablissement, TableService, $scope, DataUtils, UserExtra) {
        var vm = this;
        vm.filtrer = false;
        vm.searchItem = {
            "etablissementId": null
        };
        vm.resendActivationItem = function resendActivationItem($event, user) {
            $http.post('api/users/' + user.login + '/password').then(
                function () {
                    Notification.success("Le mot de passe a été envoyée avec succès");
                },
                function () {
                });
        };
        console.log("faire une copy du compte d'utilisateur");


        vm.copyItem = function (item, $event) {
            console.log("faire une copy du compte d'utilisateur");
            console.log(item);
            $uibModal.open({
                templateUrl: 'app/entities/user-extra/user-management-dialog.html',
                controller: 'UserManagementDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'md',
                resolve: {
                    entity: item,
                    vue: false,
                    copy: true
                }
            }).result.then(function (res) {
                    if (angular.isDefined(res)) {
                        vm.loadAll();
                        Notification.success('Enregistrement reussit !');
                        /*  $uibModalInstance.close({
                              'result': res.result,
                              'isEdit': res.isEdit
                          });*/
                    }
                },
                function () {

                });
        }

        vm.etablissements = Etablissement.query({
            size: GLOBAL_CONSTANTS.MAX_ITEMS_PER_PAGE,
            sort: 'nom,asc'
        });
        vm.initFiltre = function () {
            vm.filtrer = !vm.filtrer;
            if (!vm.filtrer) {
                vm.searchItem = {
                    "etablissementId": null
                };
                vm.init();
            }
        }


        vm.init = function init() {
            var addItemData = {
                templateUrl: 'app/entities/user-extra/user-management-dialog.html',
                controller: 'UserManagementDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'md',
                resolve: {
                    entity: function () {
                        return {
                            id: null,
                            login: null,
                            firstName: null,
                            lastName: null,
                            email: null,
                            activated: true,
                            langKey: null,
                            createdBy: null,
                            createdDate: null,
                            lastModifiedBy: null,
                            lastModifiedDate: null,
                            resetDate: null,
                            resetKey: null,
                            authorities: null
                        };
                    },
                    vue: false,
                    copy: false
                }
            };


            /**
             *Modification d'un élément
             */
            var editItemData = {
                templateUrl: 'app/entities/user-extra/user-management-dialog.html',
                controller: 'UserManagementDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'md',
                resolve: {
                    entity: null,
                    vue: false,
                    copy: false
                }
            };

            /**
             * Suppresion d'un élément
             */
            var deleteItemData = {
                templateUrl: 'app/entities/user-extra/user-extra-delete-dialog.html',
                controller: 'UserExtraDeleteController',
                controllerAs: 'vm',
                size: 'sm',
                resolve: {
                    entity: null,
                    vue: false
                }
            };

            var detailItemData = {
                templateUrl: 'app/entities/user-extra/user-management-dialog.html',
                controller: 'UserManagementDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'md',
                resolve: {
                    entity: null,
                    vue: true
                }
            };

            var dialogData = {
                addData: addItemData,
                editData: editItemData,
                deleteData: deleteItemData,
                detailData: detailItemData
            };
            var entitySearchParams = function () {
                return {};
            };

            var entityParams = function () {
                return {
                    etablissementId: vm.searchItem.etablissementId
                };
            };

            var datas = {
                scope: $scope,
                vm: vm,
                entity: UserExtra,
                entitySearch: UserExtra,
                dialogData: dialogData,
                entitySearchParams: entitySearchParams,
                entityParams: entityParams
            };
            TableService.init(datas);
        }
        vm.init();

    }
})();
