(function() {
    'use strict';

    angular
        .module('josdaviApp')
        .controller('ParametreController', ParametreController);

    ParametreController.$inject = ['$scope', '$state', 'Parametre', 'TableService'];

    function ParametreController($scope, $state, Parametre, TableService) {

        var vm = this;
        var addItemData = {
            templateUrl: 'app/entities/parametre/parametre-dialog.html',
            controller: 'ParametreDialogController',
            controllerAs: 'vm',
            backdrop: 'static',
            size: 'md',
            resolve: {
                entity: function() {
                    return {
                        valeur:null,
                        type:'SYSTEM',
                        nom: null,
                        id: null
                    };
                }
            }
        };



        /**
         *Modification d'un élément
         */
        var editItemData = {
            templateUrl: 'app/entities/parametre/parametre-dialog.html',
            controller: 'ParametreDialogController',
            controllerAs: 'vm',
            backdrop: 'static',
            size: 'md',
            resolve: {
                entity: null
            }
        };

        /**
         * Suppresion d'un élément
         */
        var deleteItemData = {
            templateUrl: 'app/entities/parametre/parametre-delete-dialog.html',
            controller: 'ParametreDeleteController',
            controllerAs: 'vm',
            size: 'sm',
            resolve: {
                entity: null
            }
        };

        var dialogData = {
            addData: addItemData,
            editData: editItemData,
            deleteData: deleteItemData
        };
        var entitySearchParams = function() {
            return {

            };
        };

        var entityParams = function() {
            return {

            };
        };

        var datas = {
            scope: $scope,
            vm: vm,
            entity: Parametre,
            entitySearch: Parametre,
            dialogData: dialogData,
            entitySearchParams: entitySearchParams,
            entityParams: entityParams
        };
        TableService.init(datas);
    }
})();
