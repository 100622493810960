/**
 * @author v.lugovsky
 * created on 15.12.2015
 */
(function() {
    'use strict';
    angular.module('BlurAdmin.theme', [
        'chart.js',
        /*'toastr',
        'angular-chartist',
        'angular.morris-chart',*/
        'textAngular',
        'BlurAdmin.theme.components',
        'BlurAdmin.theme.inputs'
    ]);
    angular.module('BlurAdmin', [
        'ngAnimate',
        'ui.bootstrap',
        'ui.router',
        'ngTouch',
        /*'toastr',
        'ui.sortable',
        'smart-table',
        "xeditable",
        'ngJsTree',
        'angular-progress-button-styles',*/
        'ui.slimscroll',
        'BlurAdmin.theme'
    ]);

})();