(function () {
    'use strict';

    angular
        .module('josdaviApp')
        .controller('AbonnementController', AbonnementController);

    AbonnementController.$inject = ['GLOBAL_CONSTANTS', 'Etablissement', '$scope', 'TableService', 'Abonnement'];

    function AbonnementController(GLOBAL_CONSTANTS, Etablissement, $scope, TableService, Abonnement) {

        var vm = this;
        vm.etablissementId = null;
        vm.etablissements = Etablissement.query({
            size: GLOBAL_CONSTANTS.MAX_ITEMS_PER_PAGE,
            sort: 'nom,asc'
        });

        $scope.$watch("vm.etablissementId", function (id) {
            vm.loadAll();
        });
        var addItemData = {
            templateUrl: 'app/entities/abonnement/abonnement-dialog.html',
            controller: 'AbonnementDialogController',
            controllerAs: 'vm',
            backdrop: 'static',
            size: 'md',
            resolve: {
                entity: function () {
                    return {
                        numero: null,
                        dateDebut: null,
                        dateFin: null,
                        cloturee: true,
                        montant: null,
                        etat: "ACTIF",
                        statut: 'ACTIF',
                        id: null
                    };
                }
            }
        };


        /**
         *Modification d'un élément
         */
        var editItemData = {
            templateUrl: 'app/entities/abonnement/abonnement-dialog.html',
            controller: 'AbonnementDialogController',
            controllerAs: 'vm',
            backdrop: 'static',
            size: 'md',
            resolve: {
                entity: null
            }
        };

        /**
         * Suppresion d'un élément
         */
        var deleteItemData = {
            templateUrl: 'app/entities/abonnement/abonnement-delete-dialog.html',
            controller: 'AbonnementDeleteController',
            controllerAs: 'vm',
            size: 'sm',
            resolve: {
                entity: null
            }
        };

        var dialogData = {
            addData: addItemData,
            editData: editItemData,
            deleteData: deleteItemData
        };

        var entityParams = function () {
            return {
                etablissementId: vm.etablissementId
            };
        };

        var datas = {
            scope: $scope,
            vm: vm,
            entity: Abonnement,
            entitySearch: Abonnement,
            dialogData: dialogData,
            entitySearchParams: entityParams,
            entityParams: entityParams
        };
        TableService.init(datas);
    }
})();
