(function () {
    'use strict';

    angular
        .module('josdaviApp')
        .controller('UserManagementController', UserManagementController);

    UserManagementController.$inject = ['Notification', '$http', '$scope', 'Principal', 'User', 'TableService', '$state', 'JhiLanguageService'];

    function UserManagementController(Notification, $http, $scope, Principal, User, TableService, $state, JhiLanguageService) {
        var vm = this;

        vm.authorities = ['ROLE_USER', 'ROLE_ADMIN'];
        vm.currentAccount = null;
        vm.languages = null;
        vm.setActive = setActive;
        vm.resendActivationItem = resendActivationItem;

        JhiLanguageService.getAll().then(function (languages) {
            vm.languages = languages;
        });
        Principal.identity().then(function (account) {
            vm.currentAccount = account;
        });

        function setActive(user, isActivated) {
            user.activated = isActivated;
            var userAll = {};
            userAll.userDTO = user;
            userAll.userExtraDTO = null;
            User.update(userAll, function () {
                vm.loadAll();
                vm.clear();
            });
        }

        function resendActivationItem($event, user) {
            $http.post('api/users/' + user.login + '/activate').then(
                function () {
                    Notification.success("Le mail d'activation a été envoyée avec succès");
                },
                function () {

                });
        }

        var addItemData = {
            templateUrl: 'app/admin/user-management/user-management-dialog.html',
            controller: 'UserManagementDialogController',
            controllerAs: 'vm',
            backdrop: 'static',
            size: 'md',
            resolve: {
                vue: false,
                copy: null,
                entity: function () {
                    return {
                        id: null,
                        login: null,
                        firstName: null,
                        lastName: null,
                        email: null,
                        activated: true,
                        langKey: null,
                        createdBy: null,
                        createdDate: null,
                        lastModifiedBy: null,
                        lastModifiedDate: null,
                        resetDate: null,
                        resetKey: null,
                        authorities: null
                    };
                }
            }
        };


        /**
         *Modification d'un élément
         */
        var editItemData = {
            templateUrl: 'app/admin/user-management/user-management-dialog.html',
            controller: 'UserManagementDialogController',
            controllerAs: 'vm',
            backdrop: 'static',
            size: 'md',
            resolve: {
                vue: false,
                copy: null,
                entity: null
            }
        };

        /**
         * Suppresion d'un élément
         */
        var deleteItemData = {
            templateUrl: 'app/admin/user-management/user-management-delete-dialog.html',
            controller: 'UserManagementDeleteController',
            controllerAs: 'vm',
            size: 'sm',
            resolve: {
                entity: null
            }
        };

        var dialogData = {
            addData: addItemData,
            editData: editItemData,
            deleteData: deleteItemData
        };
        var entitySearchParams = function () {
            return {};
        };

        var entityParams = function () {
            return {};
        };

        var datas = {
            scope: $scope,
            vm: vm,
            entity: User,
            entitySearch: User,
            dialogData: dialogData,
            entitySearchParams: entitySearchParams,
            entityParams: entityParams
        };
        TableService.init(datas);
    }
})();
