(function () {
    'use strict';

    angular
        .module('josdaviApp')
        .controller('RapportHeaderController', RapportHeaderController);

    RapportHeaderController.$inject = ['$http', '$scope', '$timeout', 'baConfig', 'baUtil'];

    function RapportHeaderController($http, $scope, $timeout, baConfig, baUtil) {
        var pieColor = baUtil.hexToRGB(baConfig.colors.defaultText, 0.2);
        var vm = this;
        vm.loadPieCharts = loadPieCharts;
        vm.updatePieCharts = updatePieCharts;
        vm.fetchDatas = fetchDatas;

        $scope.charts = [];
        vm.fetchDatas();
        /*
        * {
                    color: pieColor,
                    description: 'New Visits',
                    stats: '57,820',
                    icon: 'person',
                }, {
                    color: pieColor,
                    description: 'Purchases',
                    stats: '$ 89,745',
                    icon: 'money',
                }, {
                    color: pieColor,
                    description: 'Active Users',
                    stats: '178,391',
                    icon: 'face',
                }, {
                    color: pieColor,
                    description: 'Returned',
                    stats: '32,592',
                    icon: 'refresh',
                }

        * */
        function fetchDatas() {
            $http.get("api/reporting/stats?type=itb").then(
                function (res) {
                    vm.datas = res.data.data;
                    vm.titles = res.data.names;
                    for (var i = 0; i < vm.titles.length; i++) {
                        $scope.charts.push({
                            color: pieColor,
                            description: vm.titles[i],
                            stats: vm.datas[i],
                            icon: 'person'
                        });
                    }
                    $timeout(function () {
                        vm.loadPieCharts();
                        vm.updatePieCharts();
                    }, 1000);
                });
        }

        function loadPieCharts() {
            $('.chart').each(function () {
                var chart = $(this);
                chart.easyPieChart({
                    easing: 'easeOutBounce',
                    barColor: chart.attr('rel'),
                    trackColor: 'rgba(0,0,0,0)',
                    size: 84,
                    scaleLength: 0,
                    animation: 2000,
                    lineWidth: 9,
                    lineCap: 'round'
                });
            });
        }

        function updatePieCharts() {
            $('.pie-charts .chart').each(function (index, chart) {
                $(chart).data('easyPieChart').update(100);
            });
        }
    }
})();
