(function () {
    'use strict';

    angular
        .module('josdaviApp')
        .controller('EleveDialogController', EleveDialogController);

    EleveDialogController.$inject = ['Restangular', '$timeout', '$scope', '$uibModalInstance', 'DataUtils', 'entity', 'Eleve'];

    function EleveDialogController(Restangular, $timeout, $scope, $uibModalInstance, DataUtils, entity, Eleve) {
        var vm = this;

        vm.eleve = entity;
        vm.clear = clear;
        vm.datePickerOpenStatus = {};
        vm.openCalendar = openCalendar;
        vm.byteSize = DataUtils.byteSize;
        vm.openFile = DataUtils.openFile;
        vm.save = save;
        vm.loadAssistants = loadAssistants;
        vm.assistants = [];

        if (vm.eleve.dateNaissance != null) {
            vm.eleve.dateNaissance = new Date(vm.eleve.dateNaissance);
        }

        $timeout(function () {
            angular.element("input[type='text']:eq(0)").focus();
        });

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        function save() {
            vm.isSaving = true;
            if (vm.eleve.id !== null) {
                console.log(vm.eleve);
                Eleve.update(vm.eleve, onSaveSuccess, onSaveError);
            } else {
                Eleve.save(vm.eleve, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess(result) {
            $scope.$emit('josdaviApp:eleveUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError() {
            vm.isSaving = false;
        }

        vm.datePickerOpenStatus.dateNaissance = false;

        vm.setPhoto = function ($file, eleve) {
            if ($file && $file.$error === 'pattern') {
                return;
            }
            if ($file) {
                DataUtils.toBase64($file, function (base64Data) {
                    $scope.$apply(function () {
                        eleve.photo = base64Data;
                        eleve.photoContentType = $file.type;
                    });
                });
            }
        };

        function openCalendar(date) {
            vm.datePickerOpenStatus[date] = true;
        }

        vm.assistantColumns = [{
            name: 'code',
            title: 'Code'
        }, {
            name: 'nom',
            title: 'Nom'
        }, {
            name: 'prenom',
            title: 'Prénom'
        }];

        $scope.$on("onLoadAssistants", function () {
            vm.loadAssistants();
        });

        function loadAssistants() {
            Restangular.all('user-extras').getList().then(function (assistants) {
                vm.assistants = [];
                angular.forEach(assistants, function (value, key) {
                    var a = {};
                    a.id = value.userDTO.id;
                    a.nom = value.userDTO.lastName;
                    a.prenom = value.userDTO.firstName;
                    a.code = value.userExtraDTO.code;
                    vm.assistants.push(a);
                });
                console.log(vm.assistants);
            });
        }

        initDatas();

        function initDatas() {
            Restangular.all('user-extras').getList().then(function (assistants) {
                vm.assistants = [];
                angular.forEach(assistants, function (value, key) {
                    var a = {};
                    a.id = value.userDTO.id;
                    a.nom = value.userDTO.lastName;
                    a.prenom = value.userDTO.firstName;
                    a.code = value.userExtraDTO.code;
                    vm.assistants.push(a);
                });
                if (vm.eleve.id != null) {
                    if (vm.eleve.assistantId != null) {
                        vm.assistant = _.find(vm.assistants, {
                            'id': vm.eleve.assistantId
                        });
                    }

                }
            });
        }

        $scope.$watch("vm.eleve.assistantId", function (id) {
            if (id !== undefined && id !== null) {
                vm.assistant = _.find(vm.assistants, {
                    'id': id
                });
            }
        });

    }
})();
