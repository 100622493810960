(function() {
    'use strict';

    angular
        .module('josdaviApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('parameters.importation', {
                parent: 'parameters',
                url: '/Importations',
                title: 'Importations',
                sidebarMeta: {
                    icon: 'fa fa-import',
                    order: 12
                },
                data: {
                    authorities: ['ROLE_ADMIN','ROLE_RESPONSABLE'],
                    pageTitle: 'josdaviApp.importation.home.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/importation/importations.html',
                        controller: 'ImportationController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('importation');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }]
                }
            });
    }

})();
