(function() {
    "use strict";
    angular.module('josdaviApp').factory('JValidator', srvc);

    function srvc() {

        function hasError(form, field) {
            return field!==undefined && (form.$submitted || field.$touched) && field.$invalid;
        }

        return {
        	hasError : hasError
        };
    }
})();