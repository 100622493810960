/**
 * @author v.lugovksy
 * created on 16.12.2015
 */
(function() {
    'use strict';

    BaSidebarCtrl.$inject = ["$rootScope", "$scope", "baSidebarService", "Account"];
    angular.module('BlurAdmin.theme.components')
        .controller('BaSidebarCtrl', BaSidebarCtrl);

    /** @ngInject */
    function BaSidebarCtrl($rootScope, $scope, baSidebarService, Account) {

        loadSidebar();
        $scope.hoverItem = function($event) {
            $scope.showHoverElem = true;
            $scope.hoverElemHeight = $event.currentTarget.clientHeight;
            var menuTopValue = 66;
            $scope.hoverElemTop = $event.currentTarget.getBoundingClientRect().top - menuTopValue;
        };

        $scope.$on('$stateChangeSuccess', function() {
            if (baSidebarService.canSidebarBeHidden()) {
                baSidebarService.setMenuCollapsed(true);
            }
        });

        $scope.$on('onLogoutSuccess', function(){
            loadSidebar();
        });        

        $scope.$on('onLoginSuccess', function(){
            loadSidebar();
        });

        function loadSidebar() {
          $rootScope.$menuItems = [];
            Account.get().$promise
                .then(function(account) {
                    $rootScope.$menuItems = baSidebarService.getMenuItems(account.data);
                    $scope.defaultSidebarState = $scope.$menuItems[0].stateRef;
                })
                .catch(function() {
                    $rootScope.$menuItems = baSidebarService.getMenuItems(null);
                    $scope.defaultSidebarState = angular.isDefined($rootScope.$menuItems[0]) && $rootScope.$menuItems[0].stateRef;
                });
        }

    }
})();