(function () {
    'use strict';

    angular
        .module('josdaviApp')
        .controller('AbonnementDialogController', AbonnementDialogController);

    AbonnementDialogController.$inject = ['GLOBAL_CONSTANTS', 'Notification', 'Restangular', '$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'Abonnement', 'Parent', 'Eleve'];

    function AbonnementDialogController(GLOBAL_CONSTANTS, Notification, Restangular, $timeout, $scope, $stateParams, $uibModalInstance, entity, Abonnement, Parent, Eleve) {
        var vm = this;

        vm.dateFormat = GLOBAL_CONSTANTS.DATE_FORMAT;
        vm.abonnement = entity;
        vm.clear = clear;
        vm.datePickerOpenStatus = {};
        vm.openCalendar = openCalendar;
        vm.save = save;
        //Affichage des dates à l'edition
        if (vm.abonnement.dateDebut != null) {
            vm.abonnement.dateDebut = new Date(vm.abonnement.dateDebut);
        }
        if (vm.abonnement.dateFin != null) {
            vm.abonnement.dateFin = new Date(vm.abonnement.dateFin);
        }

        $timeout(function () {
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        function save() {
            vm.isSaving = true;
            if (vm.abonnement.id !== null) {
                Abonnement.update(vm.abonnement, onSaveSuccess, onSaveError);
            } else {
                Abonnement.save(vm.abonnement, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess(result) {
            $scope.$emit('josdaviApp:abonnementUpdate', result);
            Notification.success('Enregistrement reussit !');
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError() {
            vm.isSaving = false;
        }

        vm.datePickerOpenStatus.dateDebut = false;
        vm.datePickerOpenStatus.dateFin = false;

        function openCalendar(date) {
            vm.datePickerOpenStatus[date] = true;
        }
    }
})();
