
(function () {
    'use strict';

    angular.module('josdaviApp')
        .directive('reportHeader', dashboardPieChart);

    /** @ngInject */
    function dashboardPieChart() {
        return {
            restrict: 'E',
            controller: 'RapportHeaderController',
            templateUrl: 'app/entities/rapport/rapport-header.html'
        };
    }
})();
