(function () {
    'use strict';

    angular
        .module('josdaviApp')
        .controller('ActiviteAssistantController', ActiviteAssistantController);

    ActiviteAssistantController.$inject = ['$resource', 'Notification', 'GLOBAL_CONSTANTS', 'EtablissementClasses', 'Etablissement', 'Restangular', 'Principal', '$scope', '$state', 'ActiviteAssistant', 'TableService'];

    function ActiviteAssistantController($resource, Notification, GLOBAL_CONSTANTS, EtablissementClasses, Etablissement, Restangular, Principal, $scope, $state, ActiviteAssistant, TableService) {

        var vm = this;
        vm.loadEtablissements = loadEtablissements;
        vm.loadClasses = loadClasses;
        vm.openCalendar = openCalendar;
        vm.renvoyer = renvoyer;

        vm.loadEtablissements();
        vm.datePickerOpenStatus = {};
        vm.datePickerOpenStatus.date = false;

        function openCalendar(date) {
            vm.datePickerOpenStatus[date] = true;
        }

        vm.date = null;
        vm.predicate = 'date';
        vm.etablissementId = null;
        vm.classeId = null;
        vm.telephone = null;
        vm.moyenCommunication = null;


        $scope.$watch("vm.etablissementId", function (id) {
            vm.etablissementId = id || null;
            if (id !== undefined && id !== null) {
                vm.loadClasses(id);
            }
            vm.loadAll();
        });
        $scope.$watch("vm.date", function (d) {
            vm.loadAll();
        });

        $scope.$watch("vm.type", function (d) {
            vm.loadAll();
        });

        $scope.$watch("vm.telephone", function (d) {
            vm.loadAll();
        });

        $scope.$watch("vm.moyenCommunication", function (d) {
            vm.loadAll();
        });

        function loadClasses(etablissementId) {
            EtablissementClasses.query({
                id: etablissementId,
                size: GLOBAL_CONSTANTS.MAX_ITEMS_PER_PAGE,
                sort: 'nom,asc'
            }, function (res) {
                vm.classes = res;
            });
        }

        $scope.$watch("vm.classeId", function (id) {
            vm.classeId = id || null;
            vm.loadAll();
        });

        function loadEtablissements() {
            vm.etablissements = Etablissement.query({
                size: GLOBAL_CONSTANTS.MAX_ITEMS_PER_PAGE,
                sort: 'nom,asc'
            });
        }

        var addItemData = {
            templateUrl: 'app/entities/activite-assistant/activite-assistant-dialog.html',
            controller: 'ActiviteAssistantDialogController',
            controllerAs: 'vm',
            backdrop: 'static',
            size: 'md',
            resolve: {
                entity: function () {
                    return {
                        date: null,
                        titre: null,
                        contenu: null,
                        type: null,
                        nomAssistant: null,
                        concerne: null,
                        id: null
                    };
                }
            }
        };


        /**
         *Modification d'un élément
         */
        var editItemData = {
            templateUrl: 'app/entities/activite-assistant/activite-assistant-dialog.html',
            controller: 'ActiviteAssistantDialogController',
            controllerAs: 'vm',
            backdrop: 'static',
            size: 'md',
            resolve: {
                entity: null
            }
        };

        /**
         * Suppresion d'un élément
         */
        var deleteItemData = {
            templateUrl: 'app/entities/activite-assistant/activite-assistant-delete-dialog.html',
            controller: 'ActiviteAssistantDeleteController',
            controllerAs: 'vm',
            size: 'sm',
            resolve: {
                entity: null
            }
        };

        var dialogData = {
            addData: addItemData,
            editData: editItemData,
            deleteData: deleteItemData
        };


        var entitySearchParams = function () {
            return {};
        };
        var entityParams = function () {
            return {
                date: vm.date,
                classeId: vm.classeId,
                etablissementId: vm.etablissementId,
                telephone: vm.telephone,
                canal: vm.moyenCommunication,
                type: vm.type
            };
        };

        var datas = {
            scope: $scope,
            vm: vm,
            entity: ActiviteAssistant,
            entitySearch: ActiviteAssistant,
            dialogData: dialogData,
            entitySearchParams: entitySearchParams,
            entityParams: entityParams
        };
        TableService.init(datas);

        function renvoyer(id) {
            $resource('api/activite-assistants/:id/renvoyer', {id: id}, {}).save(function (res) {
                Notification.success("Le message a été renvoyé !");
            });
        }

    }
})();
