(function () {
    'use strict';

    angular
        .module('josdaviApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('parameters.annee-scolaire', {
                parent: 'parameters',
                url: '/annee-scolaire',
                title: "Année-Scolaire",
                sidebarMeta: {
                    icon: 'glyphicon glyphicon-tower',
                    order: 8
                },
                data: {
                    authorities: ['ROLE_ADMIN'],
                    pageTitle: 'josdaviApp.anneeScolaire.home.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/annee-scolaire/annee-scolaires.html',
                        controller: 'AnneeScolaireController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('anneeScolaire');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }]
                }
            }).state('annee-scolaire-periode', {
            parent: 'parameters.annee-scolaire',
            url: '/annee-scolaire-periodes',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'josdaviApp.periode.home.title'
            },
            views: {
                'anneeScolaire-periodes': {
                    templateUrl: 'app/entities/periode/periodes.html',
                    controller: 'PeriodeController',
                    controllerAs: 'vm'
                }
            },
            params: {
                anneeScolaire: null
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('periode');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        });
    }

})();
