(function () {
    "use strict";
    angular.module("josdaviApp")
        .constant('GLOBAL_CONSTANTS', {
            'DATE_FORMAT': 'dd/MM/yyyy',
            'DATE_FORMAT_FR': 'jj/mm/an',
            'DATETIME_FORMAT': 'dd/MM/yyyy à HH:MM',
            'DEFAULT_ITEMS_PER_PAGE': 15,
            'MAX_ITEMS_PER_PAGE': 10000000,
            'RESEAUX': ['TELMOB', 'ORANGE', 'TELECEL', 'AUTRES', 'TOUS']
        });
})();
