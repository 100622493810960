(function () {
    'use strict';
    angular
        .module('josdaviApp')
        .factory('ActiviteAssistant', ActiviteAssistant)
        .factory("ActiviteAssistantOfClasse", ActiviteAssistantOfClasse)
        .factory("ActiviteAssistantOfEtablissement", ActiviteAssistantOfEtablissement);
    ActiviteAssistant.$inject = ['$resource', 'DateUtils'];
    ActiviteAssistantOfEtablissement.$inject = ['$resource'];
    ActiviteAssistantOfClasse.$inject = ['$resource'];

    function ActiviteAssistant($resource, DateUtils) {
        var resourceUrl = 'api/activite-assistants/:id';

        return $resource(resourceUrl, {}, {
            'query': {method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.date = DateUtils.convertDateTimeFromServer(data.date);
                    }
                    return data;
                }
            }
        });
    }

    function ActiviteAssistantOfClasse($resource) {
        var resourceUrl = 'api/classes/:id/activite-assistants';

        return $resource(resourceUrl, {}, {
            'query': {method: 'GET', isArray: true}
        });
    }

    function ActiviteAssistantOfEtablissement($resource) {
        var resourceUrl = 'api/etablissements/:id/activite-assistants';

        return $resource(resourceUrl, {}, {
            'query': {method: 'GET', isArray: true}
        });
    }

})();
