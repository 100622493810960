(function() {
    'use strict';

    angular.module('BlurAdmin.theme.components')
        .provider('baSidebarService', baSidebarServiceProvider);

    /** @ngInject */
    function baSidebarServiceProvider() {
        var staticMenuItems = [];

        this.addStaticItem = function() {
            staticMenuItems.push.apply(staticMenuItems, arguments);
        };

        /** @ngInject */
        this.$get = ["$rootScope", "$state", "layoutSizes", function($rootScope, $state, layoutSizes) {
            return new _factory();

            function _factory() {
                var isMenuCollapsed = shouldMenuBeCollapsed();
                this.getMenuItems = function(account) {
                    var states = defineMenuItemStates(account);
                    var menuItems = states.filter(function(item) {
                        return item.level === 0;
                    });

                    menuItems.forEach(function(item) {
                        var children = states.filter(function(child) {
                            return child.level === 1 && child.name.indexOf(item.name) === 0;
                        });
                        item.subMenu = children.length ? children : null;
                    });

                    return menuItems.concat(staticMenuItems);
                };

                this.shouldMenuBeCollapsed = shouldMenuBeCollapsed;
                this.canSidebarBeHidden = canSidebarBeHidden;

                this.setMenuCollapsed = function(isCollapsed) {
                    isMenuCollapsed = isCollapsed;
                };

                this.isMenuCollapsed = function() {
                    return isMenuCollapsed;
                };

                this.toggleMenuCollapsed = function() {
                    isMenuCollapsed = !isMenuCollapsed;
                };

                this.getAllStateRefsRecursive = function(item) {
                    var result = [];
                    _iterateSubItems(item);
                    return result;

                    function _iterateSubItems(currentItem) {
                        currentItem.subMenu && currentItem.subMenu.forEach(function(subItem) {
                            subItem.stateRef && result.push(subItem.stateRef);
                            _iterateSubItems(subItem);
                        });
                    }
                };

                function defineMenuItemStates(account) {
                    return $state.get()
                        .filter(function(s) {
                            var hasRoles = false;
                            if (account === null) { //L'utilisateur n'est pas connecté !
                                hasRoles = (
                                  s.data === undefined ||
                                  s.data.authorities === undefined ||
                                  s.data.authorities.length === 0);
                            } else {//L'utilisateur est connecté, on vérifie ses droits
                                hasRoles = (s.data === undefined || s.data.authorities === undefined ||
                                    s.data.authorities.length === 0 ||
                                    s.data.authorities.filter(function(val) {
                                        return account.authorities !== undefined && account.authorities.indexOf(val) >= 0;
                                    }).length>0);
                            }

                            return s.sidebarMeta && hasRoles;
                        })
                        .map(function(s) {
                            var meta = s.sidebarMeta;
                            return {
                                name: s.name,
                                title: s.title,
                                level: (s.name.match(/\./g) || []).length,
                                order: meta.order,
                                icon: meta.icon,
                                stateRef: s.name
                            };
                        })
                        .sort(function(a, b) {
                            return (a.level - b.level) * 100 + a.order - b.order;
                        });
                }

                function shouldMenuBeCollapsed() {
                    return window.innerWidth <= layoutSizes.resWidthCollapseSidebar;
                }

                function canSidebarBeHidden() {
                    return window.innerWidth <= layoutSizes.resWidthHideSidebar;
                }
            }

        }];
        this.$get.$inject = ["$state", "layoutSizes"];

    }
})();
