(function() {
    'use strict';
    angular
        .module('josdaviApp')
        .factory('SeanceCours', SeanceCours)
        .factory('SeanceCoursByClasse', SeanceCoursByClasse);

    SeanceCoursByClasse.$inject = ['$resource'];
    SeanceCours.$inject = ['$resource'];

    function SeanceCours ($resource) {
        var resourceUrl =  'api/seance-cours/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'update': { method:'PUT' }
        });
    }
        function SeanceCoursByClasse ($resource) {
        var resourceUrl =  '/api/seance-cours/:id/classe';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'update': { method:'PUT' }
        });
    }
})();
