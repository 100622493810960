(function() {
    'use strict';

    angular
        .module('josdaviApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('parameters.seance-cours', {
                parent: 'parameters',
                url: '/seance-cours',
                title: 'Seance cours',
                sidebarMeta: {
                    icon: 'glyphicon glyphicon-tower',
                    order: 8
                },
                data: {
                    authorities: ['ROLE_RESPONSABLE_INSCRIPTION','ROLE_RESPONSABLE','ROLE_ADMIN'],
                    pageTitle: 'josdaviApp.seanceCours.home.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/seance-cours/seance-cours.html',
                        controller: 'SeanceCoursController',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    page: {
                        value: '1',
                        squash: true
                    },
                    sort: {
                        value: 'id,asc',
                        squash: true
                    },
                    search: null
                },
                resolve: {
                    pagingParams: ['$stateParams', 'PaginationUtil', function($stateParams, PaginationUtil) {
                        return {
                            page: PaginationUtil.parsePage($stateParams.page),
                            sort: $stateParams.sort,
                            predicate: PaginationUtil.parsePredicate($stateParams.sort),
                            ascending: PaginationUtil.parseAscending($stateParams.sort),
                            search: $stateParams.search
                        };
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('seanceCours');
                        $translatePartialLoader.addPart('typeSeance');
                        $translatePartialLoader.addPart('jourSemaine');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }]
                }
            });
    }

})();
