(function () {
    'use strict';

    angular
        .module('josdaviApp')
        .controller('ClasseController', ClasseController);

    ClasseController.$inject = ['$stateParams', 'EtablissementClasses', '$scope', 'TableService'];

    function ClasseController($stateParams, EtablissementClasses, $scope, TableService) {

        var vm = this;
        vm.etablissement = $stateParams.etablissement;

        var addItemData = {
            templateUrl: 'app/entities/classe/classe-dialog.html',
            controller: 'ClasseDialogController',
            controllerAs: 'vm',
            backdrop: 'static',
            size: 'md',
            resolve: {
                entity: function () {
                    return {
                        nom: null,
                        niveau: null,
                        cycle: null,
                        id: null,
                        personnel: false,
                        etablissementId: vm.etablissement.id,
                        etablissementNom: vm.etablissement.nom
                    };
                }
            }
        };


        /**
         *Modification d'un élément
         */
        var editItemData = {
            templateUrl: 'app/entities/classe/classe-dialog.html',
            controller: 'ClasseDialogController',
            controllerAs: 'vm',
            backdrop: 'static',
            size: 'md',
            resolve: {
                entity: null
            }
        };

        /**
         * Suppresion d'un élément
         */
        var deleteItemData = {
            templateUrl: 'app/entities/classe/classe-delete-dialog.html',
            controller: 'ClasseDeleteController',
            controllerAs: 'vm',
            size: 'sm',
            resolve: {
                entity: null
            }
        };

        var dialogData = {
            addData: addItemData,
            editData: editItemData,
            deleteData: deleteItemData
        };
        var entitySearchParams = function () {
            return {};
        };

        var entityParams = function () {
            return {
                id: vm.etablissement.id
            };
        };

        var datas = {
            scope: $scope,
            vm: vm,
            entity: EtablissementClasses,
            entitySearch: EtablissementClasses,
            dialogData: dialogData,
            entitySearchParams: entitySearchParams,
            entityParams: entityParams
        };
        TableService.init(datas);
    }
})();
