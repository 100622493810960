(function() {
    'use strict';

    angular
        .module('josdaviApp')
        .controller('LangueDialogController', LangueDialogController);

    LangueDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'Langue'];

    function LangueDialogController ($timeout, $scope, $stateParams, $uibModalInstance, entity, Langue) {
        var vm = this;

        vm.langue = entity;
        vm.clear = clear;
        vm.save = save;

        $timeout(function (){
            angular.element("input[type='text']:eq(0)").focus();
        });
        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;
            if (vm.langue.id !== null) {
                Langue.update(vm.langue, onSaveSuccess, onSaveError);
            } else {
                Langue.save(vm.langue, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('josdaviApp:langueUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }

    }
})();
