(function () {
    'use strict';

    angular
        .module('josdaviApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('eleve', {
                parent: 'entity',
                url: '/eleves',
                title: 'Elèves',
                sidebarMeta: {
                    icon: 'fa fa-child',
                    order: 3
                },
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_RESPONSABLE_INSCRIPTION', 'ROLE_RESPONSABLE'],
                    pageTitle: 'josdaviApp.eleve.home.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/eleve/eleves.html',
                        controller: 'EleveController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('eleve');
                        $translatePartialLoader.addPart('sexe');
                        $translatePartialLoader.addPart('statutArchivage');
                        $translatePartialLoader.addPart('parent');
                        $translatePartialLoader.addPart('moyenCommunication');
                        $translatePartialLoader.addPart('abonnement');
                        $translatePartialLoader.addPart('etatAbonnement');
                        $translatePartialLoader.addPart('statutArchivage');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }]
                }
            });
    }

})();
