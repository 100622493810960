(function () {
    'use strict';
    angular
        .module('josdaviApp')
        .factory('Etablissement', Etablissement)
        .factory('EtablissementClasses', EtablissementClasses);

    Etablissement.$inject = ['$resource'];
    EtablissementClasses.$inject = ['$resource'];

    function Etablissement($resource) {
        var resourceUrl = 'api/etablissements/:id';

        return $resource(resourceUrl, {}, {
            'query': {method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'update': {method: 'PUT'}
        });
    }

    function EtablissementClasses($resource) {
        var resourceUrl = 'api/etablissements/:id/classes';

        return $resource(resourceUrl, {}, {
            'query': {method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'update': {method: 'PUT'}
        });
    }
})();
