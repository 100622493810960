(function () {
    'use strict';

    angular
        .module('josdaviApp')
        .controller('EmploieTempsDialogController', EmploieTempsDialogController);

    EmploieTempsDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'EmploieTemps'];

    function EmploieTempsDialogController($timeout, $scope, $stateParams, $uibModalInstance, entity, EmploieTemps) {
        var vm = this;

        vm.emploieTemps = entity;
        vm.clear = clear;
        vm.save = save;

        $timeout(function () {
            angular.element("input[type='text']:eq(0)").focus();
        });

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        function save() {
            vm.isSaving = true;
            if (vm.emploieTemps.id !== null) {
                EmploieTemps.update(vm.emploieTemps, onSaveSuccess, onSaveError);
            } else {
                EmploieTemps.save(vm.emploieTemps, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess(result) {
            $scope.$emit('josdaviApp:emploieTempsUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError() {
            vm.isSaving = false;
        }


    }
})();
